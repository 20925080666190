import { useState, useEffect, Fragment } from "react";
import Skeleton from "@mui/material/Skeleton";
import AnexarComprovante from "../AnexarComprovante";
import { Grid, Typography, Box, Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useQuery } from "react-query";
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import useStyles from "./styles";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";
import CancelarPedidoCambio from "../CancelaPedidoCambio";
import FormasPagamentoSelecionado from "../PedidoConcluidoPagamentoEscolhido";
import TipoStatusPedido from "../../util/typesTipoStatusPedido";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Delivery from "../Delivery";
import TiposPedido from "../../util/typesTipoPedido";
import { formatMoneyInStringForm } from "../../util/helpers";
import DadosEntregaPedido from "../DadosEntregaPedido";
import TiposStatusPedido from "../../util/typesTipoStatusPedido";
import { useTheme } from "@mui/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function DetalhePedidoCambio({ dadosPedido, isLoading }) {
  const { user } = useAuth();
  const { showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyles();
  const [openModalPagamento, setOpenModalPagamento] = useState(false);
  const theme = useTheme();

  const {
    data: comprovantes,
    isLoading: isLoadingComprovantes,
    refetch: refetchComprovantes,
  } = useQuery(
    PEDIDOCOMPROVANTES,
    () => getComprovantePedidoByIDPedido(user.id, dadosPedido?.id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );


  useEffect(() => {
    if (dadosPedido?.id > 0) refetchComprovantes();
  }, [dadosPedido?.id]);

  const possuiEntrega = () => {
    if (!!dadosPedido?.pontoRetirada) {
      return true;
    }
    if (!!dadosPedido?.regiaoEntrega) {
      return true;
    }
    if (dadosPedido?.tipoPedido?.id === TiposPedido.CompraEspecie) {
      return true;
    }
    if (dadosPedido?.tipoPedido?.id === TiposPedido.VendaEspecie) {
      return true;
    }

    return false;
  };

  return (
    <>

      <Grid item xs={12}>
        <Grid>
          {isLoading &&
            [...Array(3)].map((_, index) => (
              <Skeleton width="auto" key={index} height={120} sx={{ mt: -4 }} />
            ))}
          {!isLoading && (
            <List sx={{ width: "100%" }}>
              <Box className={classes.Detalhes}>
                <Box className={classes.TitleBox}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                    }}
                  >
                    DETALHES DO PEDIDO
                    <span className={classes.Codigo}> #{dadosPedido?.id}</span>
                    <ListItem
                      sx={{ color: dadosPedido?.status?.id === TiposStatusPedido.Cancelado ? theme.palette.error.dark : theme.palette.primary.main }}
                      secondaryAction={
                        <ListItemText
                          primary={dadosPedido?.status?.descricao}

                        />
                      }
                      disablePadding
                    >
                      <ListItemText className={classes.Item}>
                        Status Pedido:
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <ListItemText
                          primary={format(
                            new Date(dadosPedido?.dataCadastro),
                            "dd/MM/yyyy"
                          )}
                        />
                      }
                      disablePadding
                    >
                      <ListItemText className={classes.Item}>
                        Data do pedido:
                      </ListItemText>
                    </ListItem>
                  </Typography>
                </Box>
                {dadosPedido?.status.id === 1 && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CancelarPedidoCambio
                      idPedido={dadosPedido?.id}
                      idCliente={user.id}
                    />
                  </Box>
                )}
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid sx={{ width: "100%" }} item lg={12}>
                    <Item>
                      <Box className={classes.ItensPedido}>
                        <Typography className={classes.Titulo}>
                          Itens do seu pedido
                        </Typography>
                        <Divider sx={{ mt: 1 }} />
                        {dadosPedido?.itens?.length > 0 &&
                          dadosPedido?.itens?.map((item, index) => (
                            <Fragment key={item?.id + index}>
                              <List
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <ListItem
                                  secondaryAction={<ListItemText />}
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    {item?.moeda?.codigoBacen === "998" ? (
                                      <Box
                                        component="img"
                                        sx={{
                                          width: 20,
                                          mr: 1,
                                          ml: 1,
                                        }}
                                        alt="bandeira"
                                        src={`../../../../images/Bandeiras/${item?.moeda?.codigoBacen}.ico`}
                                      />
                                    ) : (
                                      <Box
                                        component="img"
                                        sx={{
                                          width: 20,
                                          mr: 1,
                                          ml: 1,
                                        }}
                                        alt="bandeira"
                                        src={`../../../../images/Bandeiras/${item?.moeda?.codigoBacen}.svg`}
                                      />
                                    )}
                                    {item?.valorME}x {item?.moeda?.descricao}
                                  </ListItemText>
                                </ListItem>
                              </List>
                            </Fragment>
                          ))}

                        {dadosPedido?.tipoPedido &&
                          dadosPedido?.tipoPedido.id === 3 && (
                            <>
                              <List
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <ListItem disablePadding>
                                  <ListItemText className={classes.Item}>
                                    Natureza: {""}
                                    {dadosPedido?.itens[0]?.natureza?.descricao}
                                  </ListItemText>
                                </ListItem>
                              </List>
                            </>
                          )}
                      </Box>
                    </Item>
                    <Item>
                      <List className={classes.formaPagamento}>
                        <Typography className={classes.Titulo}>
                          Forma de pagamento
                        </Typography>
                        <Divider sx={{ mt: 1 }}></Divider>
                        <ListItem>
                          <ListItemText>
                            <FormasPagamentoSelecionado
                              pedido={dadosPedido}
                              isTodosDetalhes={false}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Item>
                  </Grid>
                  <Grid item lg={12} sx={{ width: "100%" }}>

                    {dadosPedido?.tipoPedido &&
                      dadosPedido?.tipoPedido.id !== 4 &&
                      <Item>
                        <Typography sx={{ ml: 1 }} className={classes.Titulo}>
                          <FilePresentIcon
                            color="black"
                            fontSize="10"
                            sx={{ mr: 1 }}
                          />
                          Comprovantes de pagamento
                        </Typography>
                        <Divider sx={{ mt: 1 }} />

                        <AnexarComprovante
                          loading={isLoadingComprovantes}
                          documentos={comprovantes}
                          idPedido={dadosPedido?.id}
                          userID={user.id}
                          permiteUpload={
                            dadosPedido?.status && dadosPedido?.status?.id === 1
                          }
                        />
                      </Item>
                    }


                    {possuiEntrega() && (
                      <Item sx={{ mt: 2 }}>
                        <Typography
                          sx={{ ml: 1, mt: 1 }}
                          className={classes.Titulo}
                        >
                          <DeliveryDiningIcon
                            color="text"
                            sx={{ mr: 1, opacity: 0.5 }}
                          />
                          Entrega selecionada
                        </Typography>
                        <Divider></Divider>

                        <Delivery
                          endereco={dadosPedido?.EnderecoEntrega}
                          regiaoEntrega={dadosPedido?.regiaoEntrega}
                          pontoRetirada={dadosPedido?.pontoRetirada}
                        />
                      </Item>
                    )}

                    {dadosPedido?.dadosEntregaPedido?.length > 0 && (
                      <Item sx={{ mt: 2 }}>
                        <Typography
                          sx={{ ml: 1, mt: 1 }}
                          className={classes.Titulo}
                        >
                          <DeliveryDiningIcon
                            color="text"
                            sx={{ mr: 1, opacity: 0.5 }}
                          />
                          Dados de Entrega
                        </Typography>
                        <Divider></Divider>
                        <DadosEntregaPedido
                          dadosEntrega={dadosPedido?.dadosEntregaPedido}
                        />
                      </Item>
                    )}
                    <Item sx={{ paddingBottom: "50px", background: "#fcfcfc" }}>
                      <Box sx={{ pl: 1 }} className={classes.ItensPedido}>
                        <List>
                          <Divider sx={{}}></Divider>

                          {/* DADOS REMESSA */}
                        </List>

                        {dadosPedido &&
                          dadosPedido?.itens?.map((item, index) => (
                            <Fragment key={item?.id ?? index}>
                              <List
                                sx={{
                                  width: "100%",
                                  pb: 0,
                                }}
                              >
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={
                                        <Typography>
                                          {"R$ " + item?.taxaAplicada}{" "}
                                          <Tooltip title="Taxa da moeda sem calcular o IOF">
                                            <IconButton>
                                              <HelpOutlineIcon
                                                fontSize="small"
                                                color="black"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Typography>
                                      }
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    Taxa da moeda:
                                  </ListItemText>
                                </ListItem>
                              </List>
                              {item?.moeda?.multiplicador > 0 && (
                                <List
                                  sx={{
                                    width: "100%",
                                    pb: 0,
                                  }}
                                >
                                  <ListItem
                                    secondaryAction={
                                      <ListItemText
                                        primary={
                                          <Typography>
                                            {"R$ " +
                                              item?.taxaAplicada *
                                              item?.moeda?.multiplicador}{" "}
                                            <Tooltip title="Taxa da moeda multiplicado pelo peso">
                                              <IconButton>
                                                <HelpOutlineIcon
                                                  fontSize="small"
                                                  color="black"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Typography>
                                        }
                                      />
                                    }
                                    disablePadding
                                  >
                                    <ListItemText className={classes.Item}>
                                      Taxa da moeda x peso (g):
                                    </ListItemText>
                                  </ListItem>
                                </List>
                              )}
                              <List
                                sx={{
                                  width: "100%",
                                  pb: 0,
                                }}
                              >
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={
                                        <Typography>
                                          {"R$ " + dadosPedido?.valorTotalIOF}{" "}
                                          <Tooltip title="Esse imposto é o cobrado em operações financeiras como crédito, câmbio e seguros.">
                                            <IconButton>
                                              <HelpOutlineIcon
                                                fontSize="small"
                                                color="black"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Typography>
                                      }
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    Valor IOF:
                                  </ListItemText>
                                </ListItem>
                              </List>
                              <List
                                sx={{
                                  width: "100%",
                                  pb: 0,
                                }}
                              >
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={
                                        <Typography>
                                          {"R$ " + item?.valorEfetivoTotal}
                                          <Tooltip title="O Valor Efetivo Total (VET) representa o custo de uma operação de câmbio em reais por moeda estrangeira, englobando a taxa de câmbio, as tarifas e tributos incidentes sobre essa operação.">
                                            <IconButton>
                                              <HelpOutlineIcon
                                                fontSize="small"
                                                color="black"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Typography>
                                      }
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    VET:
                                  </ListItemText>
                                </ListItem>
                              </List>
                            </Fragment>
                          ))}

                        {!!dadosPedido?.itens[0]?.impostoIR?.valorRecolhe && (
                          <List
                            sx={{
                              width: "100%",
                              pb: 0,
                            }}
                          >
                            <ListItem
                              secondaryAction={
                                <ListItemText
                                  primary={
                                    <Typography>
                                      {"R$ " +
                                        dadosPedido?.itens[0]?.impostoIR
                                          ?.valorRecolhe}

                                      <Tooltip title="Esse imposto é o cobrado em operações financeiras como crédito, câmbio e seguros.">
                                        <IconButton>
                                          <HelpOutlineIcon
                                            fontSize="small"
                                            color="black"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </Typography>
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemText className={classes.Item}>
                                Valor IR:
                              </ListItemText>
                            </ListItem>
                          </List>
                        )}

                        {!!dadosPedido?.itens[0]?.impostoISS?.valorRecolhe && (
                          <List
                            sx={{
                              width: "100%",
                              pb: 0,
                            }}
                          >
                            <ListItem
                              secondaryAction={
                                <ListItemText
                                  primary={
                                    "R$ " +
                                    dadosPedido?.itens[0]?.impostoISS
                                      ?.valorRecolhe
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemText className={classes.Item}>
                                Valor ISS:
                              </ListItemText>
                            </ListItem>
                          </List>
                        )}

                        {!!dadosPedido?.itens[0]?.impostoCIDE?.valorRecolhe && (
                          <List
                            sx={{
                              width: "100%",
                              pb: 0,
                            }}
                          >
                            <ListItem
                              secondaryAction={
                                <ListItemText
                                  primary={
                                    <Typography>
                                      {"R$ " +
                                        dadosPedido?.itens[0]?.impostoCIDE
                                          .valorRecolhe}{" "}
                                      <Tooltip title="Esse imposto é o cobrado em operações financeiras como crédito, câmbio e seguros.">
                                        <IconButton>
                                          <HelpOutlineIcon
                                            fontSize="small"
                                            color="black"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </Typography>
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemText className={classes.Item}>
                                Valor CIDE:
                              </ListItemText>
                            </ListItem>
                          </List>
                        )}

                        <List
                          sx={{
                            width: "100%",
                            pb: 0,
                          }}
                        >
                          <ListItem
                            secondaryAction={
                              <ListItemText
                                primary={
                                  "+  R$ " +
                                  formatMoneyInStringForm(
                                    dadosPedido?.valorTotalAdicional,
                                    2
                                  )
                                }
                              />
                            }
                            disablePadding
                          >
                            <ListItemText className={classes.Item}>
                              Valor adicional:
                            </ListItemText>
                          </ListItem>
                        </List>



                        {/* CASO CANCELADO */}
                        {dadosPedido?.status &&
                          dadosPedido?.status.id ===
                          TipoStatusPedido.Cancelado && (
                            <List
                              sx={{
                                width: "100%",
                                pb: 0,
                                pt: 1,
                              }}
                            >
                              <ListItem disablePadding>
                                <ListItemText>
                                  <Typography color="error">
                                    Motivo do cancelamento:
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemText>
                                  {dadosPedido?.motivoCancelamento}
                                </ListItemText>
                              </ListItem>
                            </List>
                          )}

                        <List>
                          <ListItem
                            style={{ borderTop: "1px solid #ccc" }}
                            secondaryAction={
                              <ListItemText
                                primary={
                                  <Typography
                                    color="black"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 20,
                                      mt: 4,
                                    }}
                                  >
                                    {"R$ " +
                                      dadosPedido?.valorTotalMoedaNacional}
                                  </Typography>
                                }
                              />
                            }
                            disablePadding
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  color="black"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: 18,
                                    mt: 4,
                                  }}
                                >
                                  Valor Total:
                                </Typography>
                              }
                            ></ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>

                {/* TODO: sumir com este botão caso o pedido já esteja cancelado, ainda nao foi retirado para poder arrumar layout */}
              </Box>
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
}

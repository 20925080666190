import { useState, useEffect } from "react";
import useAlert from "../../../hooks/useAlert/index";
import ButtonAcaoGrande from "../../Botoes/ButtonAcaoGrande";
import CircularProgress from "@mui/material/CircularProgress";
import FlightIcon from "@mui/icons-material/Flight";
import Seletor from "../../Seletor";
import { Grid, Box, Typography, Hidden } from "@mui/material";
import { DateRangePicker } from "rsuite";
import SearchIcon from "@mui/icons-material/Search";
import {
  MenuDashboard,
  PaginasMenuDashboard,
  SubMenusDashBoard,
  BreadCrumbsPaginas,
} from "../..";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PassageiroList from "../../PassageiroList";

import Itens from "./Itens";

import {
  getVitrine,
  addItemVitrine,
  getCoberturas,
} from "../../../api/Carrinho/SeguroViagem";
import { useAuth } from "../../../hooks/AuthContext";

import { Style } from "./styles";
import { useSeguro } from "../../../store/seguroViagem";

function VitrineSeguroViagem() {
  const { user } = useAuth();
  const classes = Style();
  const [dadosSeguro, setDadosSeguro] = useState(null);
  const [isSearch, setIsSearch] = useState(true);
  const { showAlertError } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [texto, setTexto] = useState("Buscar");
  const [isShowItens, setIsShowItens] = useState(false);
  const [destinos, setDestinos] = useState([]);
  const { infoSeguro, addInfoSeguro, updatePacotes } = useSeguro();

  const [form, setForm] = useState({
    idDestino: 1,
    idCliente: user.id,
    idHotsite: user.idHotsite ?? 0,
    passageiros: [],
  });

  const styles = {
    width: "100%",
    display: "block",
    marginBottom: 10,
    borderRadius: "7px",
    border: "0.5px solid #ccc",
    background: "#fafafa !important",
  };

  const breadCrumbs = [
    {
      label: "Seguro de viagem",
      icon: <CompareArrowsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Compra de seguro de viagem",
      icon: <DoubleArrowIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  const desativarDatasPassadas = (date) => {
    let today = new Date();
    let day = today.getDate() - 1;
    today.setDate(day)
    return date < today;
  };

  const init = async () => {
    try {
      const response = await getVitrine();

      if (!!response?.errorMessage) throw new Error(response.errorMessage);

      setDestinos(response.data.destinos);

      setForm((prevState) =>
      ({
        ...prevState, idDestino: response.data.destinos[0].id,
        descricaoDestino: response.data.destinos[0].descricao
      }))

    } catch (error) {
      showAlertError("Ops Algo deu errado: " + error.message);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handlerChangeDestino = (id, descricao) => {
    setForm({ ...form, idDestino: id, descricaoDestino: descricao });
  };

  const handlerChangeData = (data) => {
    const ate = new Date(data[1]);
    const de = new Date(data[0]);

    let formNew = form;

    formNew.dataDe = `${de.getFullYear()}-${de.getMonth() + 1}-${de.getDate()}`;

    formNew.dataAte = `${ate.getFullYear()}-${ate.getMonth() + 1
      }-${ate.getDate()}`;

    setForm(formNew);
  };

  const handlerChangePassageiro = (p) => {
    setForm(() => ({ ...form, passageiros: p }));
  };

  const handlerSearch = async () => {
    setIsLoading(true);

    try {
      if (!form?.hasOwnProperty("dataDe") || !form?.hasOwnProperty("dataAte"))
        throw new Error(
          "É necessario informar a data de ida e de volta da sua viagem. - RV5H9"
        );

      if (form.passageiros.length <= 0)
        throw new Error(
          "É necessario preencher a idade de pelo menos 1 passageiro. - RUT1X"
        );

      addInfoSeguro(form);
      const response = await addItemVitrine(form);

      if (!!response?.errorMessage) throw new Error(response.errorMessage);
      // setIsSearch(false);

      addInfoSeguro(form);
      updatePacotes(response.data);

      setIsLoading(false);

      setIsShowItens(true);
      setDadosSeguro(response.data);
    } catch (error) {
      setIsLoading(false);

      showAlertError(`Ops, Algo deu errado: ${error.message}`);
      setTexto("Refazer busca");
    }
  };

  return (
    <div sx={{ background: "#fcfcfc" }}>
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            background: "rgba(255, 255, 255, 0.8)",
            width: "100%",
            height: "100%",
            zIndex: 2000,
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" size={80} thickness={4} />
            <FlightIcon color="primary" style={{ marginTop: 10 }} />
          </div>
        </Box>
      )}


      <MenuDashboard
        pagina={PaginasMenuDashboard.Remessas}
        subMenuSelecionado={SubMenusDashBoard.Envio}
      >
        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>
        {isSearch && (
          <Grid
            container
            className={classes.Container}
            item
            xs={12}
            md={12}
            lg={12}
          >
            {" "}
            <Grid
              item
              xs={12}
              md={12}
              lg={9}
              style={{
                margin: "auto",
                justifyContent: "center",
                background: "#fff",
                borderRadius: 15,
              }}
            ></Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <Grid
                className={classes.Vitrine}
                container
                item
                xs={12}
                md={12}
                lg={9}
                xl={8}
              >
                <Grid lg={12} xs={12}>
                  <Typography
                    className={classes.Title}
                    variant="h5"
                    color="text"
                  >
                    Seguros de viagem
                  </Typography>
                </Grid>
                <Grid
                  className={classes.Seletor}
                  sx={{ mt: 1, mb: 2, pr: 0, borderRight: "1px solid #eee" }}
                  lg={3}
                  xs={12}
                >
                  {" "}
                  <Typography
                    sx={{ opacity: 0.9, fontSize: 11, marginBottom: "6px" }}
                  >
                    DESTINO{" "}
                  </Typography>
                  {destinos && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Seletor
                        setSelectIdDesc={handlerChangeDestino}
                        list={destinos}
                        size="medium"
                        // label="QUAL SEU DESTINO"
                        variant="outlined"
                        className={classes.Seletor}
                        fullWidth={true}
                        value={form.idDestino}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid sx={{ mt: 1 }} lg={4} xs={12}>
                  <Typography sx={{ mb: 0.6, opacity: 0.9, fontSize: 11 }}>
                    DATA DA SUA VIAGEM
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DateRangePicker
                      character={" até "}
                      // renderExtraFooter={() => `Hoje: ${formattedToday}`}
                      showWeekNumbers
                      appearance="default"
                      size="lg"
                      placement="bottomEnd"
                      showOneCalendar
                      preventOverflow
                      ranges={[]}
                      format="dd-MM-yyyy"
                      placeholder="Ida e Volta"
                      onChange={handlerChangeData}
                      style={styles}
                      maxDate={new Date()}
                      disabledDate={desativarDatasPassadas}
                      locale={{
                        sunday: "Dom",
                        monday: "Seg",
                        tuesday: "Ter",
                        wednesday: "Qua",
                        thursday: "Qui",
                        friday: "Sex",
                        saturday: "Sáb",
                        ok: "OK",
                        today: "Hoje",
                        yesterday: "Ontem",
                        last7Days: "Últimos 7 Dias",
                        hours: "Horas",
                        minutes: "Minutos",
                        seconds: "Segundos",
                        custom: "Personalizado",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  className={classes.passageiros}
                  sx={{ mt: 1 }}
                  lg={2}
                  xs={12}
                >
                  <Typography sx={{ mb: 0, opacity: 0.8, fontSize: 12 }}>
                    PASSAGEIROS
                  </Typography>
                  <Box sx={{ display: "block", alignItems: "center" }}>
                    <PassageiroList onChange={handlerChangePassageiro} />
                  </Box>
                </Grid>
                <Grid className={classes.ButtonBuscar} sx={{}} lg={2} md={12}>
                  <ButtonAcaoGrande
                    sx={{ height: 50 }}
                    Handle={handlerSearch}
                    texto={texto}
                    loading={isLoading}
                    className={classes.buttonSearch}
                  >
                    <SearchIcon sx={{ mr: 0.3 }} fontSize="small"></SearchIcon>
                  </ButtonAcaoGrande>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isShowItens && infoSeguro.pacotes?.length > 0 && (
          <Grid
            container
            sx={{ m: 2, display: "flex", alignItems: "baseline" }}
            lg={12}
          >
            {/* <Grid
              className={classes.Vitrine2}
              container
              item
              xs={12}
              md={12}
              lg={4}
            >
              <Grid sx={{ p: 1 }} lg={12} xs={12}>
                <Typography
                  sx={{ mb: 1, fontWeight: "bold", mt: 0 }}
                  variant="h5"
                  color="text"
                >
                  Seguros de viagem
                </Typography>
              </Grid>
              <Grid
                className={classes.Seletor}
                sx={{
                  mt: 1,
                  mb: 2,
                  pr: 0,
                  borderRight: "1px solid #eee",
                  ml: 1,
                }}
                lg={12}
                xs={12}
              >
                {" "}
                <Typography
                  sx={{ opacity: 0.9, fontSize: 11, marginBottom: "6px" }}
                >
                  DESTINO{" "}
                </Typography>
                {destinos && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Seletor
                      setSelect={handlerChangeDestino}
                      list={destinos}
                      size="medium"
                      // label="QUAL SEU DESTINO"
                      variant="outlined"
                      className={classes.Seletor}
                      fullWidth={true}
                      value={form.idDestino}
                    />
                  </Box>
                )}
              </Grid>
              <Grid sx={{ mt: 1, ml: 1 }} lg={12} xs={12}>
                <Typography sx={{ mb: 0.6, opacity: 0.9, fontSize: 11 }}>
                  DATA DA SUA VIAGEM
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <DateRangePicker
                    character={" até "}
                    // renderExtraFooter={() => `Hoje: ${formattedToday}`}
                    showWeekNumbers
                    appearance="default"
                    size="lg"
                    placement="bottomEnd"
                    showOneCalendar
                    preventOverflow
                    ranges={[]}
                    format="dd-MM-yyyy"
                    placeholder="Ida e Volta"
                    onChange={handlerChangeData}
                    style={styles}
                    maxDate={new Date()}
                         value={[new Date(form.dataDe), new Date(form.dataAte)]}

                    disabledDate={desativarDatasPassadas}
                    locale={{
                      sunday: "Dom",
                      monday: "Seg",
                      tuesday: "Ter",
                      wednesday: "Qua",
                      thursday: "Qui",
                      friday: "Sex",
                      saturday: "Sáb",
                      ok: "OK",
                      today: "Hoje",
                      yesterday: "Ontem",
                      last7Days: "Últimos 7 Dias",
                      hours: "Horas",
                      minutes: "Minutos",
                      seconds: "Segundos",
                      custom: "Personalizado",
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                className={classes.passageiros}
                sx={{ mt: 1 }}
                lg={12}
                xs={12}
              >
                <Typography sx={{ mb: 0, opacity: 0.8, fontSize: 12 }}>
                  PASSAGEIROS
                </Typography>
                <Box sx={{ display: "block", alignItems: "center" }}>
                  <PassageiroList onChange={handlerChangePassageiro} />
                </Box>
              </Grid>
              <Grid
                className={classes.ButtonBuscar}
                sx={{ m: 2 }}
                lg={12}
                md={12}
              >
                <ButtonAcaoGrande
                  sx={{ height: 50 }}
                  Handle={handlerSearch}
                  texto="Nova busca"
                  loading={isLoading}
                  className={classes.buttonSearch}
                >
                  <SearchIcon sx={{ mr: 0.3 }} fontSize="small"></SearchIcon>
                </ButtonAcaoGrande>
              </Grid>
            </Grid> */}
            <Grid lg={12} item>
              <Itens />
            </Grid>
          </Grid>
        )}
      </MenuDashboard>
    </div>
  );
}

export default VitrineSeguroViagem;

import { useState, useEffect } from "react";
import CampoTexto from "../../Campos/CampoTexto";
import CampoCep from "../../Campos/CampoCep";
import { Grid, Typography } from "@mui/material";
import { getEnderecoCadastro } from "../../../api/Cadastro";
import useAlert from "../../../hooks/useAlert";
import useStyle from "./styles";
import { Skeleton } from "@mui/material";
import Subtitulo from "../../Textos/Subtitulo";

export default function EnderecoPrincipal({ user, setData, isLoading }) {
  const { showAlertError, showAlertSuccess, showAlertInfo } = useAlert();
  const enderecoDefault = {
    bairro: "",
    cep: "",
    cidade: "",
    complemento: "",
    logradouro: "",
    numero: "",
    idCliente: user.id,
    idPais: 0,
    idUf: 0,
  };

  const [enderecoState, setEnderecoState] = useState(enderecoDefault);
  const [cepChanged, setCepChanged] = useState(false);

  const classes = useStyle();

  const init = async () => {
    try {
      const response = await getEnderecoCadastro(user.id);

      if (response.errorMessage && response.errorMessage.length > 0) {
        throw new Error(response.errorMessage);
      }

      if (response.data && response.data.id > 0) {
        let enderecoNovo = enderecoDefault;
        enderecoNovo.id = response.data.id;
        enderecoNovo.cep = response.data.cep;
        enderecoNovo.cidade = response.data.cidade;
        enderecoNovo.bairro = response.data.bairro;
        enderecoNovo.logradouro = response.data.logradouro;
        enderecoNovo.numero = enderecoState.numero;
        enderecoNovo.complemento = response.data.complemento;
        enderecoNovo.idUf = response.data.idUf;
        enderecoNovo.idPais = response.data.idPais;

        setEnderecoState(enderecoNovo);
        setData(enderecoNovo);
      }
    } catch (error) {
      showAlertError(error.message);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {

    let newEndereco = { ...enderecoState, [e.target.name]: e.target.value }

    setEnderecoState((prevState) => ({ ...newEndereco }));
    if (e.target.name === "cep") setCepChanged(true);

    setData(newEndereco);
  };

  const handleChangeEnderecoByCEP = (e) => {
    e = e.data;

    let newEndereco = enderecoState;
    newEndereco.cidade = e.cidade;
    newEndereco.bairro = e.bairro;
    newEndereco.logradouro = e.logradouro;
    newEndereco.ufDescricao = e.ufDescricao;
    newEndereco.idUf = e.idUf;
    newEndereco.idPais = e.idPais;
    newEndereco.numero = enderecoState.numero;

    setEnderecoState(newEndereco);
    setData(newEndereco);
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" height={40} width={"20%"} />
        ) : (
          <Subtitulo sx={{ mb: 1, fontSize: 15 }}>
            Cadastre seu endereço
          </Subtitulo>
        )}
        <Grid container>
          <Grid
            container
            xs={12}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          >
            {isLoading ? (
              <Grid item xs={6}>
                <Skeleton animation="wave" height={60} />
              </Grid>
            ) : (
              <Grid item xs={6}>
                <CampoCep
                  handlerChange={handleChange}
                  name="cep"
                  recarregar={cepChanged}
                  size="medium"
                  fullWidth={true}
                  value={enderecoState.cep}
                  label={"Cep"}
                  enable={true}
                  setEndereco={handleChangeEnderecoByCEP}
                  // Tipo de endereço sendo sempre o de correspondencia
                  idTipoEndereco={1}
                  idCliente={enderecoState.idCliente}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton animation="wave" height={60} />
              ) : (
                <CampoTexto
                  fullWidth={true}
                  handlerChange={handleChange}
                  name="rua"
                  sx={{ m: 0 }}
                  size="medium"
                  value={enderecoState.logradouro}
                  label="Rua"
                  defaulValue="Rua"
                  enable={false}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton animation="wave" height={60} />
              ) : (
                <CampoTexto
                  handlerChange={handleChange}
                  name="numero"
                  fullWidth={true}
                  size="medium"
                  value={enderecoState.numero}
                  label="Número"
                  enable={true}
                  defaulValue="Número"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton animation="wave" height={60} />
              ) : (
                <CampoTexto
                  fullWidth={true}
                  handlerChange={handleChange}
                  name="complemento"
                  size="medium"
                  value={enderecoState.complemento}
                  label="Complemento"
                  enable={true}
                  defaulValue="Complemento"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isLoading ? (
                <Skeleton animation="wave" height={60} />
              ) : (
                <CampoTexto
                  fullWidth={true}
                  handlerChange={handleChange}
                  name="bairro"
                  size="medium"
                  value={enderecoState.bairro}
                  label="Bairro"
                  enable={false}
                  defaulValue="Bairro"
                />
              )}
            </Grid>

            <Grid item xs={6}>
              {" "}
              {isLoading ? (
                <Skeleton animation="wave" height={60} />
              ) : (
                <CampoTexto
                  fullWidth={true}
                  handlerChange={handleChange}
                  name="cidade"
                  size="medium"
                  value={enderecoState.cidade}
                  label="Cidade"
                  defaulValue="Cidade"
                  enable={false}
                />
              )}
            </Grid>

            <Grid item xs={6}>
              {" "}
              {isLoading ? (
                <Skeleton animation="wave" height={60} />
              ) : (
                <CampoTexto
                  fullWidth={true}
                  handlerChange={handleChange}
                  name="UfDescricao"
                  size="medium"
                  value={enderecoState.ufDescricao}
                  label={"UF "}
                  defaulValue="UF"
                  enable={false}

                />
              )}
            </Grid>

          </Grid>
        </Grid>
      </div>
    </>
  );
}

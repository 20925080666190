import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ? ======================== Chamadas Mui =============================

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Badge, Skeleton, Typography } from "@mui/material";
import List from "@mui/material/List";

// ? ======================== Chamadas componentes =============================
import {
  CupomDesconto,
  Subtitulo,
  Titulo,
  Timer,
  BadgeItemCarrinho,
} from "../../../../components";
import RemessaStyle from "./styles";
import { formatMoneyInStringForm } from "../../../../util/helpers";
import useAlert from "../../../../hooks/useAlert";
import { useAuth } from "../../../../hooks/AuthContext";

import {
  getCarrinho,
  RemoveItemByIdMoeda,
  LimparCarrinho,
} from "../../../../api/Carrinho/Turismo";

import ItemCambioCarrinho from "../../../../components/ItemCambioCarrinho";
import CarrinhoPage from "../../../../components/CarrinhoPage";
import {
  finalizar,
  configuraCartaoAdicional,
} from "../../../../api/Carrinho/Turismo";
import { useMutation, useQuery } from "react-query";
import useLojaFechada from "../../../../hooks/useLojaFechada";
import TiposPedidos from "../../../../util/typesTipoPedido";
import { StepComEntrega, StepSemEntrega } from "../../../../util/StepsTurismo";
import { clearLocalStorageTimerCarrinho } from "../../../../util/LocalStorage";
import { formatMoneyInString } from "../../../../util/helpers";
import TiposOperacaoCarrinho from "../../../../util/typesTipoPedido";
import { ConstructionOutlined } from "@mui/icons-material";

export default function ItemCarrinhoTurismo() {
  const classes = RemessaStyle();
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingConfirmePedido, setLoadingConfirmePedido] = useState(false);
  const { showAlertError, showAlertSuccess } = useAlert();
  const [urlRetorno, setUrlRetorno] = useState("");
  const [carrinho, setCarrinho] = useState({});
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [urlBack, setUrlBack] = useState("");
  const [dadosCartao, setDadosCartao] = useState({
    maximoDigitos: 0,
    codigo: "",
  });
  const [titleTipoOperacao, setTitleTipoOperacao] = useState("");

  function configURLRetorno(idTipoPedido) {
    switch (parseInt(idTipoPedido)) {
      case TiposPedidos.compraEspecie:
        setUrlRetorno("/carrinho/turismo-venda/especie/vitrine");
        break;
      case TiposPedidos.VendaEspecie:
        setUrlRetorno("/carrinho/turismo-compra/especie/vitrine");
        break;
      default:
        setUrlRetorno("/");
        break;
    }
  }

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const { refetch: refetchItens } = useQuery(
    [],
    () => getCarrinho(user.id, user.idOrigem ?? 0),
    {
      onSuccess: (data) => {
        if (!data) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum carrinho - RFFA0`
          );
          return;
        }
        if (data.errorMessage) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - R0189`
          );
          return;
        }

        if (!!!data.data || data?.data?.itens.length === 0) {
          clearLocalStorageTimerCarrinho();
          setTimeout(() => {
            navigation("/CarrinhoVazio", {
              replace: false,
            });
          }, 2000);
        }

        if (data?.data?.isContemEntrega) {
          setSteps(StepComEntrega);
          setActiveStep(3);
          setUrlBack(
            `/carrinho/turismo/pagamento/${data.data.itens[0].idTipoOperacaoCarrinho
            }/${false}`
          );
        } else {
          setSteps(StepSemEntrega);
          setActiveStep(2);
          setUrlBack(
            `/carrinho/turismo/pagamento/${data.data.itens[0].idTipoOperacaoCarrinho
            }/${true}`
          );
        }

        configURLRetorno(data.data.itens[0].idTipoOperacaoCarrinho);

        setCarrinho(data.data);
      },
      onError: (error) => {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RD302`
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const mutationRemoveItem = useMutation(RemoveItemByIdMoeda, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoadingConfirmePedido(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");

      setTimeout(() => {
        navigation(`/carrinho/turismo/entrega?remocao=true`);
      }, 2000);
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R776E `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      refetchItens();
      setTimeout(() => {
        setLoadingConfirmePedido(false);
      }, 2000);
    },
  });

  const mutationLimpaCarrinho = useMutation(LimparCarrinho, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoadingConfirmePedido(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RH762");
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - RTT89 `
      );
    },
  });

  const handlerRemoveItem = (idMoeda) => (event) => {
    mutationRemoveItem.mutate({
      idCliente: user?.id,
      idOrigem: user?.idOrigemCadastroCliente,
      idMoeda,
    });
  };

  const handleChangeAdicional = async (item, isAdicional) => {
    const response = await configuraCartaoAdicional(
      user.id,
      item.idTipoOperacaoCarrinho,
      item.idItemVitrine,
      isAdicional
    );

    if (response && !!response.errorMessage) {
      throw new Error(response.errorMessage);
    }

    refetchItens();
  };

  const handleNext = async (event) => {
    event.preventDefault();
    try {
      setLoadingConfirmePedido(true);

      if (dadosCartao?.maximoDigitos || dadosCartao?.minimoDigitos) {
        if (!dadosCartao?.codigo) {
          showAlertError(
            "Ops, algo deu errado: Dígitos do cartão estão inválidos - RC1B8"
          );
          return;
        }

        if (
          dadosCartao?.codigo &&
          dadosCartao.codigo.length > dadosCartao.maximoDigitos
        ) {
          showAlertError(
            `Ops, algo deu errado: A numeração do cartão informada ultrapassa o máximo de dígitos exigido. O máximo exigido é de ${dadosCartao.maximoDigitos} dígitos - RAF9D`
          );
          return;
        }

        if (
          dadosCartao?.codigo &&
          dadosCartao.codigo.length < dadosCartao.minimoDigitos
        ) {
          showAlertError(
            `Ops, algo deu errado: A numeração do cartão informada é menor que o mínimo de dígitos exigido. O mínimo exigido é de ${dadosCartao.minimoDigitos} dígitos - RAF9D`
          );
          return;
        }
      }

      const responseFinalizacao = await finalizar(user.id);

      if (!!responseFinalizacao.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${responseFinalizacao.errorMessage} - RF944`
        );

      if (!!!responseFinalizacao.data)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possivel finalizar o pedido - R6A92`
        );

      showAlertSuccess(
        "Oba, seu pedido foi registrado, espere um pouco e veja como efetuar o pagamento"
      );

      if (responseFinalizacao && !responseFinalizacao.errorMessage) {
        clearLocalStorageTimerCarrinho();
        mutationLimpaCarrinho.mutate({
          idCliente: user?.id,
          idOrigem: user?.idOrigemCadastroCliente,
        });
        setTimeout(() => {
          navigation(
            `/turismo/PedidoConcluidoPagamentoEscolhido/${responseFinalizacao.data}`
          );
        }, 3000);
      }
    } catch (error) {
      showAlertError(`${error} - RC6A3`);
    } finally {
      setTimeout(() => {
        setLoadingConfirmePedido(false);
      }, 2999);
    }
  };

  const handlerComprarMais = () => {
    navigation(urlRetorno);
  };

  const handleBack = () => {
    navigation(urlBack, {
      replace: false,
    });
  };

  const handlerChangeCupom = (event) => {
    setCarrinho({
      ...carrinho,
      ["cupom"]: { codigoPromocional: event.target.value },
    });
  };

  const handlerRemoveCupom = (event) => {
    setCarrinho({
      ...carrinho,
      ["cupom"]: { codigoPromocional: "" },
    });
  };

  useEffect(() => {
    if (!!!carrinho.itens) return;

    if (carrinho?.itens?.length <= 0) return;

    if (
      carrinho?.itens[0]?.idTipoOperacaoCarrinho === TiposPedidos.CompraEspecie
    ) {
      if (carrinho?.itens?.every((item) => item.moeda.isEspecie === false)) {
        setTitleTipoOperacao("Vendendo saldo do seu cartão");
        return;
      }

      setTitleTipoOperacao("Vendendo sua moeda");
    } else {
      if (carrinho.itens.every((item) => item.moeda.isEspecie === false)) {
        setTitleTipoOperacao("Comprando um novo cartão/recarga");
        return;
      }

      setTitleTipoOperacao("Comprando uma nova moeda");
    }
  }, [carrinho]);

  return (
    <>


      <CarrinhoPage
        handleBack={handleBack}
        handleNext={handleNext}
        steps={steps}
        activeStep={activeStep}
        loadingButton={loadingConfirmePedido}
        disabledVoltarCompras={false}
        handleBackVoltarComprar={handlerComprarMais}
      >
        {!!carrinho?.dataExpiracao && (
          <Timer
            active={!!carrinho.dataExpiracao}
            dataExpiracao={carrinho?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}
        <List dense className={classes.List}>
          <Grid item className={classes.BlocoConferir}>
            {loadingConfirmePedido ? (
              <Skeleton height={50} width={200} animation="wave" />
            ) : (
              <>
                <Titulo sx={{ mb: 1 }}>Confira sua operação</Titulo>
                <Titulo sx={{ fontSize: 16, mb: 3 }} color="text.secondary">
                  {titleTipoOperacao}
                </Titulo>
              </>
            )}

            {carrinho && (
              <>
                {loadingConfirmePedido ? (
                  <>
                    <Skeleton
                      height={50}
                      width={"100%"}
                      animation="wave"
                      style={{ marginBottom: ".5rem !important" }}
                    />

                    <Skeleton height={50} width={"100%"} animation="wave" />
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: ".5rem",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Região:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {carrinho?.praca?.descricao}
                      </Typography>
                    </Box>

                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        Valor da entrega:
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {
                          carrinho.valorFrete > 0 ?
                            formatMoneyInString(carrinho.valorFrete)
                            : "Grátis"
                        }
                      </Typography>
                    </Box>
                  </>
                )}

                <Divider sx={{ mt: 1 }} />
                {loadingConfirmePedido ? (
                  new Array(3)
                    .fill(1)
                    .map((i, index) => (
                      <Skeleton
                        key={index}
                        height={50}
                        width={"100%"}
                        animation="wave"
                      />
                    ))
                ) : (
                  <ItemCambioCarrinho
                    itens={carrinho.itens}
                    impostos={carrinho.impostos}
                    praca={carrinho?.praca}
                    setLoading={setLoadingConfirmePedido}
                    loading={loadingConfirmePedido}
                    handlerRemoveItem={handlerRemoveItem}
                    handleChangeAdicional={handleChangeAdicional}
                    refetchItens={refetchItens}
                    setDadosCartao={setDadosCartao}
                    dadosCartao={dadosCartao}
                  />
                )}

                <Divider sx={{ mb: 1 }} />
              </>
            )}

            {carrinho &&
              carrinho.cupom &&
              carrinho.cupom.valorDesconto &&
              (!loadingConfirmePedido ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    {" "}
                    <Subtitulo
                      color="text.secondary"
                      label="Subtotal:"
                    ></Subtitulo>
                    <Subtitulo sx={{ fontWeight: "500" }} align="right">
                      {carrinho?.itens[0]?.idTipoOperacaoCarrinho ===
                        TiposOperacaoCarrinho.CompraEspecie ? (
                        <>
                          R${" "}
                          {formatMoneyInStringForm(
                            carrinho.valorTotalMoedaNacional -
                            carrinho.cupom.valorDesconto * -1,
                            2
                          ).replace("-", "")}
                        </>
                      ) : (
                        <>
                          R${" "}
                          {formatMoneyInStringForm(
                            carrinho.cupom.valorDesconto +
                            carrinho.valorTotalMoedaNacional,
                            2
                          )}
                        </>
                      )}
                    </Subtitulo>
                  </Box>
                </>
              ) : (
                <Skeleton height={50} width={150} animation="wave" />
              ))}
          </Grid>
        </List>
        <Box>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              width: "100%",
              marginTop: "-10px",
              marginBottom: "-.3rem",
            }}
          >
            {!!carrinho?.itens?.length && (
              <CupomDesconto
                idTipoPedido={carrinho?.itens[0]?.idTipoOperacaoCarrinho}
                isLoading={loadingConfirmePedido}
                setIsLoading={setLoadingConfirmePedido}
                cupom={carrinho && carrinho?.cupom}
                idTipoMercado={1}
                idMoeda={carrinho.idMoeda}
                onReflesh={() => refetchItens()}
              />
            )}
          </Grid>
        </Box>
        <br></br>
        {carrinho &&
          carrinho?.valorTotalMoedaNacional &&
          (!loadingConfirmePedido ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 0,
              }}
            >
              <Titulo
                sx={{ fontSize: 20, fontWeight: "bold" }}
                label="Total"
              ></Titulo>

              <BadgeItemCarrinho carrinho={carrinho}>
                <Titulo
                  sx={{
                    fontWeight: "bold",
                    textAlign: "right",
                    fontSize: 20,
                    mt: 0.8,
                    mb: 4,
                  }}
                  color="primary"
                  align="right"
                >
                  R${" "}
                  {formatMoneyInStringForm(
                    carrinho?.valorTotalMoedaNacional,
                    2
                  )}
                </Titulo>
              </BadgeItemCarrinho>
            </Box>
          ) : (
            <Skeleton width={"100%"} height={50} animation="wave" />
          ))}
        <br></br>
      </CarrinhoPage>
    </>
  );
}

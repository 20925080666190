import FilePresentIcon from "@mui/icons-material/FilePresent";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Subtitulo, Titulo } from "../";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";
import { GetCanalBancarioExteriorByIDMoeda } from "../../api/FormaPagamento";
import Hand from "../../assets/images/hand.png";
import { useAuth } from "../../hooks/AuthContext";
import { formatMoneyInString } from "../../util/helpers";
import { CANALBANCARIOEXTERIOR, PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import TiposPedido from "../../util/typesTipoPedido";
import AnexarComprovante from "../AnexarComprovante";
import OperacaoBancarioRecebimento from "./OperacaoBancarioRecebimento";
import OperacoesBancarias from "./OperacoesBancarias";
import Pix from "./Pix";
import TituloPedidoConcluido from "./TituloPedidoConcluido";
import useStyle from "./style";

const PedidoConcluidoPagamentoEscolhido = ({
  pedido,
  setErrorMessage,
  isTodosDetalhes = true,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const { user } = useAuth();
  const [isRecebimento, setIsRecebimento] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState([
    { ...pedido.formaPagamento },
  ]);

  const {
    data: comprovantes,
    isLoading: isLoadingComprovantes,
    refetch: refetchComprovantes,
  } = useQuery(
    PEDIDOCOMPROVANTES,
    () => getComprovantePedidoByIDPedido(user?.id, pedido?.id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch } = useQuery(
    CANALBANCARIOEXTERIOR,
    () => GetCanalBancarioExteriorByIDMoeda(pedido?.itens[0]?.moeda?.id),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage)
          setErrorMessage(
            `Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum canal exterior - RAF4E `
          );

        setFormaPagamento(data.data);
      },
      onError: (error) => {
        setErrorMessage(
          `Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R34C8 `
        );
      },
      enabled: false,
    }
  );

  useEffect(() => {
    refetchComprovantes();
    if (pedido?.tipoPedido?.id === TiposPedido.Recebimento) {
      setIsRecebimento(true);
      refetch();
      return;
    }
  }, [pedido]);

  return (
    <>
      <Grid
        md={12}
        sm={10}
        lg={10}
        container
        className={classes.Container}
        justifyContent="center"
        alignItems="baseline"
      >
        {isTodosDetalhes && (
          <Grid item md={6} sm={12} pl={4}>
            <img width={55} src={Hand} alt="" />
            <Typography variant="h5" sx={{ mb: 1, mt: 1, fontWeight: "bold" }}>
              Recebemos seu pedido
            </Typography>
            <TituloPedidoConcluido pedido={pedido} />

            <br />
            <br />

            <Titulo
              color={"primary"}
              sx={{ fontWeight: "bold", fontSize: 30 }}
            >
              {formatMoneyInString(pedido?.valorTotalMoedaNacional || pedido?.valorTotal)}
            </Titulo>

            <br />

            {isRecebimento && (
              <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} />
                Numero da Ordem: {pedido?.ordemPagamento?.codigoOrdem}
              </Subtitulo>
            )}

            <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} /> Você
              receberá atualizações do seu pedido por e-mail e pela plataforma
            </Subtitulo>
            {console.log({ Pepido: pedido })}
            {
              pedido?.chipInternacional?.length > 0 &&
              <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} />
                Problemas com o Chip, entre em contato pelo site:
                <a href="https://ezsimconnect.com/" target="_blank" style={{ marginLeft: '.2rem', }}>https://ezsimconnect.com/</a>
              </Subtitulo>
            }


            {pedido?.formaPagamento?.tipoLiquidacao.textId !== "PIX" &&
              pedido?.tipoPedido?.id !== TiposPedido.CompraEspecie &&
              pedido?.tipoPedido?.id !== TiposPedido.SeguroViagem && (
                <div className={classes.divAnexo}>
                  <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                  <Typography sx={{ ml: 1 }} className={classes.Titulo}>
                    <FilePresentIcon
                      color="black"
                      fontSize="10"
                      sx={{ mr: 1 }}
                    />
                    Comprovantes de pagamento
                  </Typography>

                  <AnexarComprovante
                    loading={false}
                    documentos={comprovantes}
                    idPedido={pedido?.id}
                    userID={user?.id}
                    permiteUpload={pedido?.status && pedido?.status?.id === 1}
                  />
                </div>
              )}
          </Grid>
        )}

        <Grid sx={{ mt: 3 }} item md={isTodosDetalhes ? 4 : 12} sm={8}>
          {pedido?.formaPagamento?.tipoLiquidacao?.textId === "PIX" ? (
            <Pix pedido={pedido} />
          ) : isRecebimento ? (
            <OperacaoBancarioRecebimento
              dadosBancarios={pedido?.clienteDadosBancarios}
            />
          ) : (
            <>
              <OperacoesBancarias
                formaPagamento={formaPagamento}
                isTodosDetalhes={isTodosDetalhes}
              />

              {pedido?.tipoPedido?.id === TiposPedido.CompraEspecie && (
                <OperacaoBancarioRecebimento
                  dadosBancarios={pedido?.clienteDadosBancarios}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PedidoConcluidoPagamentoEscolhido;

import { useState } from "react";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { useQueryClient, useMutation } from "react-query";
import { Skeleton } from "@mui/material";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { DETALHEPEDIDOCOMERCIAL, MEUSPEDIDOSCAMBIO } from "../../util/typesReactQuery";
import useStyles from "./styles";
import { Modal, CampoTexto } from "../";
import { cancelarPedidoCambio } from "../../api/Cambio";
import useAlert from "../../hooks/useAlert";

export default function CancelaPedidoCambio({ idPedido, idCliente }) {
  const { showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [motivoCancelamento, setMotivoCancelamento] = useState("");
  const handleChange = (e) => {
    setMotivoCancelamento(e.target.value);
  };
  const handlerOpenModal = (e) => {
    setModalOpen(true);
  };

  const handlerCloseModal = (e) => {
    setModalOpen(false);
  };

  const handlerCancelar = (e) => {
    mutationCancelamentoPedido.mutate({
      idCliente: idCliente,
      idPedido: idPedido,
      motivoCancelamento: motivoCancelamento,
    });
  };

  const mutationCancelamentoPedido = useMutation(cancelarPedidoCambio, {
    //função chamada quando concluido a chamada da api
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("Cancelamento efetuado com sucesso");
        setTimeout(() => {
          handlerCloseModal();
        }, 1000);
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      setTimeout(() => {
        queryClient.refetchQueries(MEUSPEDIDOSCAMBIO);
        queryClient.refetchQueries(DETALHEPEDIDOCOMERCIAL);
      }, 2000);
    },
  });

  return (
    <>

      <Button
        style={{ marginTop: 12, marginBottom: 10, alignItems: "center", fontSize: 12 }}
        variant="contained"
        color="error"
        type="submit"
        size="small"
        onClick={handlerOpenModal}
      >
        <DoDisturbIcon fontSize="14" sx={{ mr: 1 }} />
        Cancelar Pedido
      </Button>
      <Modal hasButtonClose show={modalOpen} close={handlerCloseModal}>
        <Box>
          <Typography variant="h5" className={classes.Titulo}>
            Por favor, Informe o motivo do cancelamento de seu pedido
          </Typography>
          <CampoTexto
            isLoading={mutationCancelamentoPedido.isLoading}
            required={true}
            handlerChange={handleChange}
            name="motivoCancelamento"
            size="small"
            value={motivoCancelamento}
            enable={true}
            fullWidth={true}
            label="Motivo de cancelamento"
          />
          {mutationCancelamentoPedido.isLoading && (
            <Skeleton variant="rectangular" height={40} sx={{ mt: 1 }} />
          )}
          {!mutationCancelamentoPedido.isLoading && (
            <Button
              style={{ marginTop: 25 }}
              variant="contained"
              fullWidth
              type="submit"
              onClick={handlerCancelar}
            >
              Cancelar Pedido
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
}

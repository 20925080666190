import MenuDashboard, { Paginas } from "../../../components/MenuDashboard";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { Button, Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Titulo, Subtitulo } from "../../../components";
import useStyles from "./styles";
import ShareIcon from "@mui/icons-material/Share";
import imgTwitter from "../../../assets/images/twitter.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { configurationVariables } from "../../../environment";

import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

import { Modal } from "../../../components";
import useAlert from "../../../hooks/useAlert";

export function ConvidarAmigos() {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const { showAlertError, showAlertInfo } = useAlert();

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handlerOpenModal = (event) => {
    event.preventDefault();
    setShowModal(!showModal);
  };
  const handlerCloseModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const handleCopyClick = () => {
    copyTextToClipboard(configurationVariables.UrlLoja)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
        showAlertInfo(
          `Oba ! o link ${configurationVariables.UrlLoja} foi copiado e está pronto para ser compartilhado`
        );
      })
      .catch((err) => {
        showAlertError(
          "Ops, Algo deu errado ao tentar copiar o link de compartilhamento - R56YJ"
        );
        console.error(err);
      });
  };
  return (
    <>

      <MenuDashboard pagina={Paginas.AlterarSenha}>
        <Grid xs={12} lg={8} sx={{ p: 0, pt: 8, margin: "auto" }}>
          <Box className={classes.Card} style={{}}>
            <Grid>
              <Titulo sx={{ fontWeight: "bold", fontSize: 28, mt: 3 }}>
                Convide amigos enviando um link
              </Titulo>
            </Grid>
            <Subtitulo sx={{ mb: 0, mt: 1 }}>
              Parece um ótimo momento para trazer alguém para realizar operações
              online.
            </Subtitulo>{" "}
            <Grid xs={12} lg={9} sx={{ mt: 6 }}>
              <Box sx={{ p: 0, borderRadius: 5 }}>
                <Subtitulo sx={{ mb: 0 }}>Compartilhe o seu link</Subtitulo>
                <Grid
                  xs={12}
                  sx={{ mt: 1, display: "flex", alignItems: "center" }}
                >
                  <TextField
                    spellCheck="false"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    readOnly
                    value={configurationVariables.UrlLoja}
                  />

                  <Button
                    variant="contained"
                    sx={{ ml: 1, textTransform: "capitalize" }}
                    onClick={handleCopyClick}
                    className={classes.Button}
                  >
                    <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
                    <span>{isCopied ? "Link copiado" : "Copiar"}</span>
                  </Button>
                </Grid>
              </Box>
            </Grid>
            <Grid
              xs={12}
              lg={10}
              sx={{ mt: 3, display: "flex", alignItems: "center" }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{ ml: 1, textTransform: "capitalize", minWidth: "10em" }}
                onClick={handlerOpenModal}
              >
                <ShareIcon fontSize="small" sx={{ mr: 1 }} />
                Compartilhar nas redes sociais
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Modal
          show={showModal}
          hasButtonClose={true}
          close={() => {
            setShowModal(!showModal);
          }}
        >
          <Titulo
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              p: 1,
              pb: 2,
              color: "#444",
            }}
          >
            Compartilhar convite
          </Titulo>
          <Divider />
          <Grid xs={12} lg={12}>
            <List sx={{ width: "100%" }}>
              <FacebookShareButton
                className={classes.ListItem}
                url={configurationVariables.UrlLoja}
                quote={"Venha conhecer a plataforma"}
                hashtag="#LojaVirtualdeCambio"
              >
                <ListItem
                  className={classes.ListItem}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRightOutlinedIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#eee" }}>
                      <FacebookOutlinedIcon sx={{ color: "#000" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        Postar no Facebook
                      </Typography>
                    }
                  />
                </ListItem>
              </FacebookShareButton>
              <TwitterShareButton
                className={classes.ListItem}
                url={configurationVariables.UrlLoja}
                quote={"Venha conhecer a plataforma"}
                hashtag="#LojaVirtualdeCambio"
              >
                <ListItem
                  className={classes.ListItem}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRightOutlinedIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#eee" }}>
                      <Box
                        component="img"
                        style={{ width: "25px", height: "25px" }}
                        src={imgTwitter}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        Postar no Twitter
                      </Typography>
                    }
                  />
                </ListItem>
              </TwitterShareButton>
              <WhatsappShareButton
                className={classes.ListItem}
                url={configurationVariables.UrlLoja}
                title="Faça seu cadastro em nossa plataforma"
              >
                <ListItem
                  className={classes.ListItem}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRightOutlinedIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#eee" }}>
                      <WhatsAppIcon sx={{ color: "#000" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                        Enviar pelo WhatsApp
                      </Typography>
                    }
                  />
                </ListItem>
              </WhatsappShareButton>{" "}
            </List>
          </Grid>

          <Divider />
          <Button
            variant="contained"
            sx={{ mt: 3, textTransform: "capitalize", minWidth: "12em" }}
            fullWidth
            onClick={handlerCloseModal}
          >
            Concluir
          </Button>
        </Modal>
      </MenuDashboard>
    </>
  );
}

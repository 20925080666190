import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { format, compareAsc, compareDesc } from "date-fns";
import { pt } from "date-fns/locale";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
} from "@mui/material";
import { useAuth } from "../../../../hooks/AuthContext";
import useAlert from "../../../../hooks/useAlert";
import { getAllOperacoesByIDClient } from "../../../../api/chamadasApi";
import { HISTORICOSALDOCLIENTE } from "../../../../util/typesReactQuery";
import {
  formatDateForString,
  formatStringForStringDate,
} from "../../../../util/helpers";
import typesOpSaldo from "../../../../util/typesSaldoReais";
import {
  CampoData,
  MenuDashboard,
  PaginasMenuDashboard,
  Seletor,
} from "../../../../components";
import useStyles from "./style";

const objOperacaoTipo = [
  {
    descricao: "Selecione a opção que deseja",
    id: 0,
  },
  {
    descricao: "Deposito",
    id: typesOpSaldo.deposito,
  },
  {
    descricao: "Saque",
    id: typesOpSaldo.saque,
  },
];

export function Historico() {
  const { user } = useAuth();

  const [historico, setHistorico] = useState([]);

  const [dataDe, setDataDe] = useState(
    new Date(formatDateForString(new Date()))
  );
  const [dataAte, setDataAte] = useState(
    new Date(formatDateForString(new Date()))
  );
  const [filterOperacao, setFilterOperacao] = useState(0);
  const classe = useStyles();

  //?Usando react-query para buscar o historico
  const {
    data: historicoOperacoes,
  } = useQuery(
    [HISTORICOSALDOCLIENTE],
    () => getAllOperacoesByIDClient(user.id),
    {
      onSuccess: (data) => {
        let date = new Date();
        setHistorico(
          data.filter(
            (saldos) =>
              compareAsc(
                new Date(saldos.dataCadastro).getDate(),
                date.getDate()
              ) === 0
          )
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  //Filtra por data, se é saque, ou deposito
  useEffect(() => {
    let dadosFiltrados = [];

    if (historicoOperacoes) {
      dadosFiltrados = historicoOperacoes.filter((saldo) => {
        let dataCadastro = formatStringForStringDate(
          saldo.dataCadastro.split("T")[0]
        );
        let dataPos = compareAsc(new Date(dataCadastro), dataDe);
        let dataAnt = compareDesc(new Date(dataCadastro), dataAte);

        if (
          (dataPos === 1 || dataPos === 0) &&
          (dataAnt === 1 || dataAnt === 0)
        ) {
          if (
            (dataPos === 1 || dataPos === 0) &&
            (dataAnt === 1 || dataAnt === 0)
          ) {
            return saldo;
          }
        }

        return saldo;
      });
    }

    if (dadosFiltrados.length > 0) {
      if (filterOperacao === typesOpSaldo.saque) {
        dadosFiltrados = dadosFiltrados.filter(
          (saldo) => saldo.tipoOperacaoSaldoMN.id === typesOpSaldo.saque
        );
      }

      if (filterOperacao === typesOpSaldo.deposito) {
        dadosFiltrados = dadosFiltrados.filter(
          (saldo) => saldo.tipoOperacaoSaldoMN.id === typesOpSaldo.deposito
        );
      }
    }
    setHistorico([...dadosFiltrados]);
  }, [filterOperacao, dataAte, dataDe]);



  const MostrarData = ({ data }) => {
    const dtCadastro = new Date(data);
    return (
      <span>
        {format(dtCadastro, "d 'de' MMM yyyy", { locale: pt })}
        <br />
        {format(dtCadastro, "'ás' kk ':' mm", { locale: pt })}
      </span>
    );
  };

  //helper para setar a data no input
  const formatDateValueInput = (value) => {
    return value.toISOString().split("T")[0];
  };

  const handlerChangeDate = (tipo) => (event) => {
    let data = new Date(formatStringForStringDate(event.target.value));
    if (tipo === "pos") {
      setDataDe(data);
    }
    if (tipo === "ant") {
      setDataAte(data);
    }
  };

  return (
    <>

      <MenuDashboard pagina={PaginasMenuDashboard.Cripto}>
        <Grid
          className={classe.BlocoCripto}
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} className={classe.Saldo}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 500,
                  alignItems: "center",
                  display: "flex",
                  padding: 10,
                }}
              >
                Histórico
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={6} md={2}>
                    <Seletor
                      isSelect={true}
                      label="Tipo Operacao"
                      list={objOperacaoTipo}
                      size="small"
                      fullWidth={true}
                      value={filterOperacao}
                      setSelect={setFilterOperacao}
                    ></Seletor>
                  </Grid>
                  <Grid item xs={2} md={2} />
                  <Grid item xs={2}>
                    <CampoData
                      type="date"
                      label="Data de:"
                      size="small"
                      onChange={handlerChangeDate("pos")}
                      value={formatDateValueInput(dataDe)}
                    />
                    <CampoData
                      type="date"
                      label="Data Até:"
                      size="small"
                      onChange={handlerChangeDate("ant")}
                      value={formatDateValueInput(dataAte)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }} className={classe.Saldo}>
            {historico && historico.length > 0 ? (
              historico.map((operacoes, index) => (
                <React.Fragment key={operacoes.id}>
                  <Box
                    style={{
                      display: "flex",
                      marginBottom: 20,
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: 500,
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {operacoes.tipoOperacaoSaldoMN.descricao} em R$
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        style={{
                          fontWeight: "normal",
                          alignItems: "center",
                        }}
                      >
                        {operacoes.valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Typography>
                    </Grid>
                    <Box>
                      <Typography
                        variant="body"
                        color="text.secondary"
                        style={{
                          fontWeight: 500,
                          alignItems: "center",
                        }}
                      >
                        <MostrarData data={operacoes.dataCadastro} />
                      </Typography>
                    </Box>
                  </Box>
                  {index !== historicoOperacoes.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <>
                <Box
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <Grid>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 500,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Nenhum dado de deposito ou saque encontrado
                    </Typography>
                  </Grid>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </MenuDashboard>
    </>
  );
}

import * as React from "react";
import { useEffect } from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ModalStyle from "./styleModal";
import { IconButton } from "@mui/material";
import useAlert from "../../hooks/useAlert";

export default function BasicModal({
  show,
  close,
  component,
  children,
  error,
  successMessage,
  hasButtonClose = false,
  sx
}) {
  const classes = ModalStyle();
  const { showAlertError, showAlertSuccess } = useAlert();

  useEffect(() => {
    if (!!successMessage)
      showAlertSuccess(successMessage);
  }, [successMessage])

  useEffect(() => {
    if (!!error)
      showAlertError(error);
  }, [error])

  return (
    <div>

      <Modal
        arial-labelledby="modal"
        open={show}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <>

          <Box className={classes.Box} sx={{ ...sx }}>
            {hasButtonClose && (
              <Box Box className={classes.buttonClose}>
                <IconButton onClick={close}>
                  <CloseIcon className={classes.IconClose} />
                </IconButton>
              </Box>
            )}
            {component ?? children}
          </Box>
        </>
      </Modal>
    </div>
  );
}

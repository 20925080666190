import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/AuthContext";
import { GETPEDIDOCONCLUIDOSEGURO } from "../../../util/typesReactQuery";
import { useQuery } from "react-query";
import { GetPedidoByID } from "../../../api/PedidoSeguroViagem";
import useAlert from "../../../hooks/useAlert";
import { LoadingPedidoRealizadoPagamentoEscolhido, MenuDashboard, PaginasMenuDashboard, PedidoConcluidoPagamentoEscolhido } from "../../../components";
import TiposPedido from "../../../util/typesTipoPedido";


export function Confirmacao() {
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { idPedido } = useParams()
  const [pedido, setPedido] = useState(null);

  useQuery([`${GETPEDIDOCONCLUIDOSEGURO}${idPedido}S`], () => GetPedidoByID(user.id, idPedido), {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhum pedido foi encontrado - RBF76 "
        );
        return;
      }

      if (!!data.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - R3256 `
        );
        return;
      }

      let dadoPedido = { ...data.data, tipoPedido: { id: TiposPedido.SeguroViagem } }

      setPedido(() => ({ ...dadoPedido }))

    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  R98CC`
      );
    },
    refetchOnWindowFocus: false,
  })

  return (
    <>

      <MenuDashboard pagina={PaginasMenuDashboard.SeguroViagem}>

        {!!pedido ?
          <PedidoConcluidoPagamentoEscolhido pedido={pedido} />
          :
          <LoadingPedidoRealizadoPagamentoEscolhido />
        }
      </MenuDashboard>
    </>
  );
}

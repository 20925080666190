import { feedbackTypes } from "..";
import { Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import { Fragment } from "react";

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: "#c5ebff",
  padding: 10,
  fontSize: '10pt',
  '&:hover': {
    backgroundColor: "#c5ebff",
  },
}));

export function FeedbackTypeStep({ onFeedbackTypeChanged }) {
  return (
    <>
      <Box

      >
        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
          <Typography color="black" sx={{ fontWeight: 500, fontSize: '14pt' }} variant="body1">
            Deixe seu feedback
          </Typography>
          <Tooltip title="Iremos analisar seu Feedback e inserir em nossas melhorias na plataforma">
            <IconButton>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Typography color="text" sx={{ fontSize: '10pt', mt: 1 }} variant="subtitle1">
          Seu comentário é importante para realizarmos melhorias na plataforma.        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          mt: 4,
          width: '100%',
          justifyContent: "center"

        }}
      >
        {Object.entries(feedbackTypes).map(([key, value]) => {
          return (
            <Fragment key={key}>
              <ColorButton
                variant="contained"
                sx={{
                  zIndex: "modal",
                  textTransform: "capitalize",
                  mb: 2,
                  mt: 2,
                  mr: 2,
                }}
                size="small"
                key={key}
                onClick={() => onFeedbackTypeChanged(value)}
              >
                {value.title === "Problema" ? (
                  <ErrorOutlineIcon sx={{ mr: 1 }} fontSize="small" />
                ) : value.title === "Ideia" ? (
                  <LightbulbOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                ) : (
                  <MapsUgcOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                )}
                <span>{value.title}</span>
              </ColorButton>
            </Fragment>
          );
        })}
      </Box>
    </>
  );
}

import { useState, useEffect } from "react";

// ? ======================== Chamadas Mui =============================

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Link from "@mui/material/Link";

// ? ======================== Chamadas componentes =============================

import Logo from "../../assets/images/logo.png";
import {
  Copyright,
  EspacoMenuLateralEsquerdo,
  EspacoConteudoLateralDireito,
  ButtonAcaoGrande,
  ButtonVoltar,
  Step as VerticalStep,
} from "../../components";

import { LogoEmpresa } from "../../components";
import RemessaStyle from "./styles";
import useAlert from "../../hooks/useAlert";
import useCliente from '../../hooks/useCliente';
import { useAuth } from "../../hooks/AuthContext";
import ButtonAcaoGrandeOutlined from "../Botoes/ButtonAcaoGrande copy";


export default function CarrinhoPage({
  children,
  handleNext,
  handleBack,
  disableButtonNext = false,
  steps,
  activeStep,
  enable = true,
  loadingButton = false,
  disabledVoltarCompras = true,
  handleBackVoltarComprar,
}) {
  const classes = RemessaStyle();
  const { showAlertError } = useAlert();
  const { signOut } = useAuth();
  const { isTokenAtivo, errorMessage } = useCliente();

  useEffect(() => {
    if (errorMessage)
      showAlertError(errorMessage);

    if (isTokenAtivo === false)
      signOut();

  }, [isTokenAtivo, errorMessage])


  return (
    <>

      <Grid container component="main" className={classes.container}>
        <Hidden smDown>
          <EspacoMenuLateralEsquerdo isDrawer={false}>
            <Link underline="hover" color="inherit" href="/">
              <Grid
                item
                md={12}
                xs={false}
                sx={{
                  pt: 4,

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LogoEmpresa />
              </Grid>
            </Link>
            <Grid
              item
              md={1}
              sm={3}
              xs={false}
              sx={{
                mx: 0,
              }}
              style={{ margin: "auto" }}
            >
              {" "}
              <VerticalStep steps={steps} activeStep={activeStep} />
            </Grid>

            <Grid
              item
              md={1}
              sm={1}
              xs={false}
              sx={{
                mx: 5,
              }}
            >
              <Copyright />
            </Grid>
          </EspacoMenuLateralEsquerdo>
        </Hidden>
        {/* MENU DIREITO */}
        <EspacoConteudoLateralDireito>
          <Hidden smDown>
            <Grid item className={classes.Bloco} lg={6}>
              <Grid item className={classes.Bloco} lg={12}>
                <ButtonVoltar
                  mostraTexto={true}
                  // sx={{color:'black'}}
                  handleVoltar={handleBack}
                ></ButtonVoltar>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item className={classes.Bloco} xs={12}></Grid>
          </Hidden>

          <Grid item className={classes.Bloco} lg={6}>
            <Hidden smUp>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <ButtonVoltar
                    mostraTexto={true}
                    // sx={{color:'black'}}
                    handleVoltar={handleBack}
                  ></ButtonVoltar>
                </Grid>
                <Grid
                  container
                  xs={false}
                  sx={{
                    pt: 2,
                    pr: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    justifyContent: "flex-end",
                  }}
                >
                  <LogoEmpresa />
                </Grid>
                {/* <Grid
                  item
                  md={11}
                  xs={false}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LogoEmpresa idHotsite={0} />
                </Grid> */}
                {/* <Grid item>
                  <Link underline="hover" color="inherit" href="/">
                    <Box
                      sx={{ width: 100 }}
                      component="img"
                      alt="Logo da corretora"
                      src={Logo}
                    />
                  </Link>
                </Grid> */}
              </Grid>
            </Hidden>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item className={classes.BlocoBeneficiario}>
                {children}
                <Box sx={{ mt: 0 }}>

                  <Grid container sx={{ alignItems: "center" }}>
                    {!disabledVoltarCompras && (
                      <Grid item xs className={classes.BotaoContinuar} sx={{ mr: 2 }}>
                        <ButtonAcaoGrandeOutlined
                          color="secondary"
                          Handle={handleBackVoltarComprar}
                          texto="Voltar as Compras"
                          variant="outlined"
                          loading={loadingButton}
                          disabled={!enable}
                        ></ButtonAcaoGrandeOutlined>
                      </Grid>
                    )}
                    {!disableButtonNext && (
                      <Grid className={classes.BotaoContinuar} item xs>

                        <ButtonAcaoGrande
                          sx={{ mt: 2 }}
                          Handle={handleNext}
                          texto="Continuar"
                          loading={loadingButton}
                          disabled={!enable}
                        ></ButtonAcaoGrande>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Hidden smUp>
            <Grid
              item
              md={1}
              sm={1}
              xs={false}
              sx={{
                mx: 5,
              }}
            ></Grid>
          </Hidden>
        </EspacoConteudoLateralDireito>
      </Grid>
    </>
  );
}

import { useState, useEffect, useRef } from "react";
import { ButtonAcaoGrande, CampoTexto, Seletor } from "../../../components/index";
import {
    saveNew,
    createPagadorRecebedorByIDClienteIDpaisIDTipo,
} from "../../../api/RecebedorEstrangeiro";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import useAlert from "../../../hooks/useAlert";
import useStyles from "../styles";
import Titulo from "../../../components/Textos/Titulo";
import TiposContaBancaria from '../../../util/typesTiposContaBancaria'
import { GetBancosNacionais } from '../../../api/BancosNacionais'
import { BANCOSNACIONAIS } from '../../../util/typesReactQuery';
import { useMutation, useQuery } from "react-query";
import { SaveNewDadosBancarios } from '../../../api/DadosBancarios'
import { useAuth } from "../../../hooks/AuthContext";
import { MultSkeleton } from '../../../components'

const TiposBancarios = [
    { id: TiposContaBancaria.contaCorrente, descricao: "Conta Corrente" },
    { id: TiposContaBancaria.poupanca, descricao: "Poupança" },
    { id: TiposContaBancaria.conjunta, descricao: "Conjunta" },
]


export default function New({
    refetchDadosBancarios,
    setError,
    setShowModal,
    setSuccessMessage
}) {
    const topo = useRef();
    const { user } = useAuth()
    const { showAlertSuccess, showAlertError } = useAlert();
    const [isLoading, setIsLoading] = useState(true);
    const [beneficiario, setBeneficiario] = useState(null);
    const [selectedTipoConta, setSelectedTipoConta] = useState(TiposContaBancaria.contaCorrente);
    const [bancosNacionais, setBancosNacionais] = useState([])
    const [selectedBancoNacionailID, setselectedBancolNacionailID] = useState(0)
    const [isPix, setIsPix] = useState(false);
    const [formData, setFormData] = useState({
        numeroAgencia: "",
        digitoAgencia: "",
        numeroConta: "",
        digitoConta: "",
        chavePIX: "",
        idBancoNacional: 0,
        idTipoContaBancaria: 0,
        idCliente: user.id
    })


    useEffect(() => {
        if (bancosNacionais.length > 0) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
    }, [bancosNacionais])



    useEffect(() => {
        setFormData({ ...formData, idBancoNacional: selectedBancoNacionailID })
    }, [selectedBancoNacionailID])

    useEffect(() => {
        setFormData({ ...formData, idTipoContaBancaria: selectedTipoConta.id })
    }, [selectedTipoConta])


    useQuery(BANCOSNACIONAIS, () => GetBancosNacionais(), {
        onSuccess: (data) => {
            if (!data || !!data.errorMessage)
                setError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi encontrado nenhum banco nacional- R07Q3 `)

            setBancosNacionais(data.data);
            setselectedBancolNacionailID(data.data[0].id)

        },
        onError: (error) => {
            setError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R09A3 `)
        },
        refetchOnWindowFocus: false,
    })



    const mutationSave = useMutation(SaveNewDadosBancarios, {
        //função chamada quando concluido a chamada da api
        onSuccess: (response) => {
            if (!!response?.errorMessage) {
                setError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel salvar os dados bancarios - RCFED `)
                return;
            }


            refetchDadosBancarios();
            setSuccessMessage("Oba !! Uma nova conta bancaria foi registrada !!")
            setShowModal(false);


        },
        //função chamada quando ocorre um erro
        onError: async (err) => {
            setError(`Ops, algo deu errado, por favor informe o suporte o seguinte error: ${err} - R00FC `)
        },

    });





    const handlerChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }


    const campoIsNotValid = (value) => {
        if (!!value)
            return false;

        return true;
    }


    const handlerSaveBanco = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            if (!isPix && campoIsNotValid(formData.digitoAgencia))
                throw new Error("O campo Digito Agencia precisa ser preenchido - R956F")

            if (!isPix && campoIsNotValid(formData.numeroAgencia))
                throw new Error("O campo Agencia precisa ser preenchido - RD2F8")

            if (!isPix && campoIsNotValid(formData.numeroConta))
                throw new Error("O campo Conta precisa ser preenchido - RDD3D")

            if (!isPix && campoIsNotValid(formData.digitoConta))
                throw new Error("O campo Digito Conta precisa ser preenchido - R0ACD")

            if (isPix && campoIsNotValid(formData.chavePIX))
                throw new Error("O campo chave Pix precisa ser preenchido - RE241")

            await mutationSave.mutate(formData);
        } catch (error) {
            setError(error.message)
        }
        finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 500)
        }




    }


    return (
        <>

            <Titulo
                ref={topo}
                sx={{
                    fontSize: "20px",
                    mb: 3,
                    mt: 2,
                    borderBottom: "1px solid #ddd",
                    paddingBottom: 2,
                }}
                label="Novo cadastro de dados bancários"
            ></Titulo>
            <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <Seletor size="small" label="Banco Nacional" isLoading={isLoading} fullWidth list={bancosNacionais} value={selectedBancoNacionailID} setSelect={setselectedBancolNacionailID} />
                </Grid>
                {!isPix &&
                    <>
                        {isLoading ?
                            <>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <MultSkeleton animation="wave" quantidade={3} height={30} />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Seletor size="small" label="Tipo da conta" isLoading={isLoading} list={TiposBancarios} fullWidth setSelect={setSelectedTipoConta} value={selectedTipoConta} />
                                </Grid>
                                <Grid item xs={7}>
                                    <CampoTexto size="small" fullWidth={true} name="numeroConta" handlerChange={handlerChange} label="Conta" value={formData.numeroConta} enable={!isLoading} isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={5}>
                                    <CampoTexto size="small" name="digitoConta" handlerChange={handlerChange} label="Digíto da Conta" value={formData.digitoConta} enable={!isLoading} isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={7} sx={{ mt: 1 }}>
                                    <CampoTexto fullWidth={true} size="small" name="numeroAgencia" handlerChange={handlerChange} label="Agencia" value={formData.numeroAgencia} enable={!isLoading} isLoading={isLoading} />
                                </Grid>
                                <Grid item xs={5} sx={{ mt: 1 }}>
                                    <CampoTexto size="small" name="digitoAgencia" handlerChange={handlerChange} label="Digíto da Agencia" value={formData.digitoAgencia} enable={!isLoading} isLoading={isLoading} />
                                </Grid>

                            </>
                        }
                    </>
                }

                <Grid item xs={12}>
                    <FormGroup size="small" >
                        {
                            isLoading ?
                                <></>
                                :
                                <FormControlLabel fullWidth={true} size="small" onClick={() => setIsPix(!isPix)} control={<Checkbox checked={isPix} />} label="Quero cadastrar chave Pix" />

                        }
                    </FormGroup>

                </Grid>
                {
                    isPix &&
                    <Grid item xs={12}>
                        {isLoading ?
                            <>
                                <MultSkeleton animation="wave" quantidade={1} height={30} />
                            </>
                            :
                            <>
                                <CampoTexto size="small" name="chavePIX" fullWidth={true} handlerChange={handlerChange} label="Chave Pix" value={formData.chavePIX} enable={!isLoading} isLoading={isLoading} />

                            </>
                        }
                    </Grid>
                }


                <Grid item xs={12}>
                    <ButtonAcaoGrande
                        style={{ marginTop: 25 }}
                        variant="contained"
                        fullWidth
                        isLoading={isLoading}
                        disabled={
                            beneficiario?.idPais === 0 ||
                            beneficiario?.idTipoPagadorRecebedor === 0
                        }

                        type="submit"
                        Handle={handlerSaveBanco}
                    >
                        Cadastrar
                    </ButtonAcaoGrande>
                </Grid>
            </Grid >



        </>
    );
}

import { useState, useEffect, Fragment } from "react";
import CampoTexto from "../../Campos/CampoTexto";
import CampoData from "../../Campos/CampoData";
import Subtitulo from "../../Textos/Subtitulo";
import Seletor from "../../Seletor";
import { Grid, Skeleton } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getRepresentante } from "../../../api/Cadastro";
import useStyle from "./styles";
import CustomGrid from "../../GridCadastroCustom";
import Controle from "../ControleCamposMultiplos";

export default function RepresentanteItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});
  const [defaultDadosForm, setDefaultDadosForm] = useState({})

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getRepresentante(
      idCliente,
      idPerfilLimiteOperacional
    );

    setDefaultDadosForm(() => ({ ...response[0] }))
    setDadosForm(() => ([...response]));
    setDada(() => ([...response]));
  };

  const formatDateValueInput = (value) => {
    let valor = "";
    if (value.includes("T")) {
      valor = value.split("T")[0];
    } else {
      valor = value;
    }

    return valor;
  };

  const handlerChangeValue = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handleChangeCheck = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.checked };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handlerChangeSelect = (name, value) => {
    setDadosForm({ ...dadosForm, [name]: value });
    setDada({ ...dadosForm, [name]: value });
  };

  return (
    <>
      {/* <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={30} />
        ) : (
          <Subtitulo color="primary" label="Representante" />
        )}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {dadosForm.campos &&
            dadosForm.campos.map((c, i, array) => (
              <Fragment key={i}>
                <CustomGrid index={i} array={array} md={3}>
                  <>
                    {c.tipoMascara === "Celular" &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <>
                          <InputMask
                            onChange={handlerChangeValue}
                            mask={"(99) 99999-9999"}
                            value={c.valor}
                            enable={c.enable}
                            maskChar="_"
                          >
                            {() => (
                              <TextField
                                spellCheck="false"
                                margin="normal"
                                required={c.obrigatorio}
                                fullWidth={true}
                                label={c.label}
                                maxLength={c.maxLength}
                                name={c.propriedade}
                                value={c.valor}
                                size="medium"
                                className={classes.campo}
                                autoFocus
                                onChange={handlerChangeValue}
                              />
                            )}
                          </InputMask>
                        </>
                      ))}

                    {c.tipoMascara === "TelefoneFixo" &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <>
                          <InputMask
                            onChange={handlerChangeValue}
                            mask={"(99) 9999-9999"}
                            value={c.valor}
                            enable={c.enable}
                            maskChar="_"
                          >
                            {() => (
                              <TextField
                                spellCheck="false"
                                margin="normal"
                                required={c.obrigatorio}
                                fullWidth={true}
                                label={c.label}
                                maxLength={c.maxLength}
                                // isLoading={true}
                                name={c.propriedade}
                                value={c.valor}
                                size="medium"
                                className={classes.campo}
                                autoFocus
                                onChange={handlerChangeValue}
                              />
                            )}
                          </InputMask>
                        </>
                      ))}

                    {c.tipoMascara === "CPF" &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <>
                          <InputMask
                            onChange={handlerChangeValue}
                            mask={"999.999.999-99"}
                            value={c.valor}
                            enable={c.enable}
                            maskChar="_"
                          >
                            {() => (
                              <TextField
                                spellCheck="false"
                                margin="normal"
                                required={c.obrigatorio}
                                fullWidth={true}
                                label={c.label}
                                maxLength={c.maxLength}
                                // isLoading={true}
                                name={c.propriedade}
                                value={c.valor}
                                size="medium"
                                className={classes.campo}
                                autoFocus
                                onChange={handlerChangeValue}
                              />
                            )}
                          </InputMask>
                        </>
                      ))}

                    {c.tipoMascara === "CEP" &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <>
                          <InputMask
                            onChange={handlerChangeValue}
                            mask={"99999-999"}
                            value={c.valor}
                            enable={c.enable}
                            maskChar="_"
                          >
                            {() => (
                              <TextField
                                spellCheck="false"
                                margin="normal"
                                required={c.obrigatorio}
                                fullWidth={true}
                                label={c.label}
                                maxLength={c.maxLength}
                                // isLoading={true}
                                name={c.propriedade}
                                value={c.valor}
                                size="medium"
                                className={classes.campo}
                                autoFocus
                                onChange={handlerChangeValue}
                              />
                            )}
                          </InputMask>
                        </>
                      ))}

                    {c.tipoControle === "Texto" &&
                      c.tipoMascara === null &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <CampoTexto
                          key={i}
                          enable={c.enable}
                          variant="filled"
                          size="medium"
                          fullWidth={false}
                          defaultValue=" "
                          label={c.label}
                          name={c.propriedade}

                          required={c.obrigatorio}
                          value={c.valor}
                          handlerChange={handlerChangeValue}
                          className={classes.campo}
                          // isLoading={isLoading}
                          maximoDigitos={c.maxLength}
                        />
                      ))}
                    {c.tipoControle === "Check" &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <FormGroup>
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                value={c.valor}
                                checked={c.valor}
                                onChange={handleChangeCheck}
                                inputProps={{ "aria-label": "controlled" }}
                                name={`${c.propriedade}`}
                              />
                            }
                            label={c.label}
                          />
                        </FormGroup>
                      ))}

                    {(c.tipoControle === "Data" || c.tipoMascara === "Data") &&
                      (isLoading ? (
                        <Skeleton animation="wave" width={"100%"} height={60} />
                      ) : (
                        <CampoData
                          required={c.obrigatorio}
                          enable={c.enable}
                          size="medium"
                          label={c.label}
                          fullWidth={true}
                          name={c.propriedade}
                          value={formatDateValueInput(c.valor)}
                          onChange={handlerChangeValue}
                          className={classes.campo}
                          isLoading={isLoading}
                        />
                      ))}
                  </>
                </CustomGrid>
              </Fragment>
            ))}
          {dadosForm.idTipoRepresentante > 0 && (
            <Grid item xs={6} md={3}>
              {isLoading ? (
                <Skeleton animation="wave" width={"100%"} height={60} />
              ) : (
                <Seletor
                  sx={{ mt: 2 }}
                  setSelect={(e) =>
                    handlerChangeSelect("idTipoRepresentante", e)
                  }
                  list={dadosForm.tiposRepresentantes}
                  size="medium"
                  label="Tipos de Representação"
                  fullWidth
                  value={dadosForm.idTipoRepresentante}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div> */}
      <Controle
        identificaItem='Representante'
        dadosForm={dadosForm}
        setDadosForm={setDadosForm}
        setData={setDada}
        defaultDadosForm={defaultDadosForm}
        title="Representantes"
        tooltipAdd="Adicione um representante"
        tooltipDelete="Remove o representante selecionado"
        initBuscaCampos={init}
      />
    </>
  );
}

import { Box, Card, CardContent, Divider, IconButton, List, ListItem, Typography, useTheme, Grid } from "@mui/material";
import usePedido from "../../hooks/usePedido";
import { formatMoneyInString } from "../../util/helpers";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";
import { PositionTooltip, TooltipInformacao } from "../TooltipInformacao";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; import { useMutation } from "react-query";
import { RemoveItemByIdMoeda } from "../../api/Carrinho/Turismo";
import useAlert from "../../hooks/useAlert";
import { useAuth } from "../../hooks/AuthContext";
import { Style } from './style'
import { Subtitulo, Titulo } from '../'
import { Info as InfoIcon } from "@mui/icons-material";

const ListItemVitrineSelecionado = ({ carrinho, loadingButton, onAddCart, setLoadingButton }) => {

    // const { itensPedido, refetchPedido: refetchTurismo } = usePedido();
    const theme = useTheme();
    const { showAlertSuccess, showAlertError } = useAlert();
    const { user } = useAuth();
    const classes = Style();



    const mutation = useMutation(RemoveItemByIdMoeda, {
        onSuccess: (data) => {
            if (!!data?.errorMessage)
                throw new Error(data.errorMessage, " - R4984");

            showAlertSuccess("Item excluido com sucesso !")


        },
        onError: async (err) => {
            console.error(err);
            showAlertError(
                `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - REDB3`
            );
        },
        onSettled: () => {
            setTimeout(() => {
                setLoadingButton(false);
            }, 2000)
            if (carrinho?.itens.length < 2) {
                let caminho = window.location.href
                window.location.href = caminho;
            }
        }

    });

    const handlerRemoveItem = (idMoeda) => (event) => {
        setLoadingButton(true);
        event.preventDefault();
        mutation.mutate({ idCliente: user.id, idOrigem: user.idOrigemCadastroCliente, idMoeda });

    };


    return <>

        <Card sx={{ mt: 1, boxShadow: 'none', background: "#fff" }}>
            <CardContent>
                <Titulo sx={{ fontSize: 20, mb: 1 }}>Itens adicionados no carrinho</Titulo>
                <Box sx={{ display: 'flex', alignContent: 'center', mb: 1 }}>
                    <TooltipInformacao size="small" positionStart={PositionTooltip.right} descricao="O pedido pode conter tarifas adicionais como: Delivery, cartões adicionais,  entre outros">
                        <InfoIcon fontSize="small" sx={{ color: 'black', mr: 1 }}></InfoIcon>
                    </TooltipInformacao>
                    <Subtitulo sx={{ fontSize: 13 }}>
                        Os valores mostrados abaixo não contemplam todas as tarifas da operacao.
                    </Subtitulo>
                </Box>
                <Divider />
                <List sx={{ width: "100%" }}>
                    <Subtitulo sx={{ fontSize: 13 }}>
                        + Adicione outro item na vitrine e clique em adicionar moeda.
                    </Subtitulo>
                    <ListItem sx={{ display: 'flex', justifyContent: 'end' }} >


                        {/* <ButtonAcaoGrande
                            sx={{ zIndex: theme.zIndex.mobileStepper, width: '50%', padding:'6px !important',mt: '0 !important'}}
                            loading={loadingButton}
                            variant="contained"
                            Handle={(e) => onAddCart(e, false)}
                        > + Adicionar Moeda
                        </ButtonAcaoGrande> */}
                    </ListItem>

                    {

                        carrinho?.itens?.map((i, index) =>

                            <ListItem key={index} className={classes.itemListMaisMoedas}>
                                <Grid xs={12} container sx={{ justifyContent: "space-between" }}>
                                    <Grid sx={{ display: 'block' }}>
                                        <Typography color="text.secondary" style={{ flexBasis: "25%" }}>
                                            Moeda:

                                        </Typography>

                                        <Typography color="" style={{ flexBasis: "25%" }}>
                                            {i.moeda.descricao}
                                        </Typography></Grid>
                                    <Grid sx={{ display: 'block' }}>

                                        <Typography color="text.secondary" style={{ flexBasis: "25%" }}>
                                            Taxa:

                                        </Typography>
                                        <Typography color="text" style={{ flexBasis: "25%" }}>
                                            {formatMoneyInString(i.valorTaxaAplicada)}

                                        </Typography>

                                    </Grid>
                                    <Grid sx={{ display: 'block' }}>

                                        <Typography color="text.secondary" style={{ flexBasis: "25%" }}>
                                            Valor:

                                        </Typography>
                                        <Typography color="text" style={{ flexBasis: "25%" }}>
                                            {formatMoneyInString(i.valorTotalMN)}

                                        </Typography>

                                    </Grid>

                                    <span style={{ flexBasis: "5%", textAlign: 'end' }}>
                                        <TooltipInformacao positionStart={PositionTooltip.right} descricao="Deleta o item atual">
                                            <IconButton onClick={handlerRemoveItem(i.moeda.id)}><DeleteOutlineIcon sx={{ fontSize: 20 }} color="error" /></IconButton>
                                        </TooltipInformacao>
                                    </span>
                                </Grid>

                            </ListItem>
                        )
                    }
                </List>
            </CardContent>
        </Card>
    </>
}

export default ListItemVitrineSelecionado;
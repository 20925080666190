import { useState, useEffect } from "react";

import Seletor from "../../../Seletor";
import Grid from "@mui/material/Grid";

import { Skeleton } from "@mui/material";
import { changeDadosPessoaFisica } from "../../../../api/Cadastro";
import { useAuth } from "../../../../hooks/AuthContext";
import InputAdornment from "@mui/material/InputAdornment";

export default function Indicações({ dadosForm, setForm, setData, isLoading }) {
  const [campos, setCampos] = useState([]);
  const { user } = useAuth();

  const startCampos = (dados) => {
    setCampos(dados);
  };

  useEffect(() => {
    startCampos(dadosForm.dadosPessoaFisica.campos);
  }, []);

  const init = async (campos, estadoCivilId, idCliente) => {
    const response = await changeDadosPessoaFisica(
      campos,
      estadoCivilId,
      idCliente
    );

    setCampos(response.data);
  };

  useEffect(() => {
    init(
      dadosForm.dadosPessoaFisica.campos,
      dadosForm.dadosPessoaFisica.idEstadoCivil,
      user.id
    );
  }, []);

  const handlerChangeSelect = (name, value) => {
    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica[name] = value;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaFisica[name] = value;
      return newCliente;
    });
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {dadosForm.dadosPessoaFisica.enableIndicacoes &&
          (isLoading ? (
            <Grid item sx={{ pr: 0 }} xs={6} md={6}>
              <Skeleton animation="wave" height={60} width={"100%"} />
            </Grid>
          ) : (
            <Grid item xs={6} md={12}>
              <Seletor
                
                sx={{ width: "100%" }}
                setSelect={(e) => handlerChangeSelect("idIndicacao", e)}
                list={dadosForm.dadosPessoaFisica.indicacoes}
                size="medium"
                fullWidth={true}
                variantTextField="outlined"
                label="Selecione seu operador*"
                value={dadosForm.dadosPessoaFisica.idIndicacao}                
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
}

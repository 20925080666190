import { Alert, AlertTitle, Badge, Box, Grid, Typography } from "@mui/material";
import useCheckPendencia from "../../hooks/useCheckPendencia";
import AlertCustomFixed from "../AlertCustomFixed";
import useAlert from "../../hooks/useAlert";
import { useEffect } from "react";
import TiposPendencia from '../../util/typesTiposPendencia'


function PendenciaCliente({ handlerPanelDocumentos, handlerPanelDadosCadastrais }) {
      const { pendencias, messageError } = useCheckPendencia();
      const { showAlertError } = useAlert();

      useEffect(() => {
            if (!!messageError) showAlertError(messageError);
      }, [messageError])

      const handlerChangePanels = (pendencia) => {
            if (!pendencia.hasOwnProperty("tipoPendencia")) {
                  console.error("Não consta a propriedade tipoPendencia");
                  return;
            }

            if (pendencia?.tipoPendencia?.id === TiposPendencia.documentos)
                  handlerPanelDocumentos();
            if (pendencia?.tipoPendencia?.id === TiposPendencia.dadosCadastrais)
                  handlerPanelDadosCadastrais();
      }

      return (
            <>

                  <Grid xs={12} md={4} lg={4} xl={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Badge color="warning" variant="dot" sx={{ mr: 1 }} />
                              <Typography>
                                    Não Impede de operar
                              </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Badge color="error" variant="dot" sx={{ mr: 1 }} />
                              Impede de operar
                        </Box>
                  </Grid >

                  {!!pendencias && pendencias.length > 0 ?
                        pendencias.map((pendencia, index) => (
                              <Grid sx={{ mt: 2 }} xs={8} key={index} >
                                    <AlertCustomFixed
                                          isHabilitaButton={true}
                                          textButton={"Resolver Agora"}
                                          handlerActionButton={() => handlerChangePanels(pendencia)}
                                          type={pendencia.impedeOperar ? "error" : "warning"}
                                          sx={{ boxShadow: "6px 6px 8px -3px #c6c6c6" }}>
                                          <AlertTitle>{pendencia.descricao}</AlertTitle>
                                          {pendencia.observacao}
                                    </AlertCustomFixed>

                              </Grid>
                        ))
                        :
                        <Grid sx={{ mt: 2 }} xs={6} >
                              <AlertCustomFixed isHabilitaButton={false} type="info"
                                    sx={{ boxShadow: "6px 6px 8px -3px #c6c6c6" }}>
                                    Você não possuí nenhuma pendência em seu cadastro.
                              </AlertCustomFixed>
                        </Grid>

                  }

            </>)


}

export default PendenciaCliente;
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// ? ======================== Chamadas Mui =============================

import { Skeleton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

// ? ======================== Chamadas componentes =============================
import { useMutation, useQuery } from "react-query";
import { finalizar, getCarrinho } from '../../../api/Carrinho/ChipInternacional';
import { Titulo } from "../../../components";
import CarrinhoPage from "../../../components/CarrinhoPage";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert";
import useLojaFechada from "../../../hooks/useLojaFechada";
import { formatMoneyInStringForm } from "../../../util/helpers";
import { GETCARRINHOCHIP } from "../../../util/typesReactQuery";
import RemessaStyle from "./styles";



const steps = [
  {
    label: "Vitrine",
    description: "",
    link: "/ChipInternacional",
  },
  {
    label: "Forma de pagamento",
    description: ``,
    link: "/ChipInternacional/Pagamento",
  },
  {
    label: "Finalizar",
    description: ``,
    link: "/ChipInternacional/Resumo",
  },
];

export default function ResumoItemChip() {
  const classes = RemessaStyle();
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingConfirmePedido, setLoadingConfirmePedido] = useState(false);
  const { showAlertError, showAlertSuccess } = useAlert();
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const [urlBack] = useState("/ChipInternacional/Pagamento");


  const [dadosChip, setDadosChip] = useState(null);

  const { isLoading } = useQuery({
    queryKey: [GETCARRINHOCHIP],
    queryFn: () => getCarrinho(user?.id),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {

      if (!data || !!data?.errorMessage) {
        showAlertError(`Ops, algo deu errado: ${data?.errorMessage} - RJSTE`)
      }
      setDadosChip(() => ({ ...data.data }));
    },
    retryDelay: 3000
  })


  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);




  const { mutateAsync: finalizarChip } = useMutation({
    mutationFn: finalizar,
    onSuccess: (data) => {
      if (!!data?.errorMessage) {
        showAlertError("Ops, Algo deu errado: " + data.errorMessage + " - RDR0L")
        return;
      }
      showAlertSuccess('Oba, estamos registrando o seu pedido, por favor aguarde!')
      setTimeout(() => {
        navigation(`/ChipInternacional/Confirmacao/${data.data}`);
      }, 1500)

    },
    onError: async (err) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - RZRFM `
      );
    },
    onSettled: () => {
      setTimeout(() => {
        setLoadingConfirmePedido(false)
      }, 2000);
    }
  });


  const handleNext = async (event) => {
    event.preventDefault();
    setLoadingConfirmePedido(true);
    await finalizarChip(user.id);
  };

  const handlerComprarMais = () => {
    navigation("/SeguroViagem");
  };

  const handleBack = () => {
    navigation(urlBack, {
      replace: false,
    });
  };


  const ContainerItem = ({ children }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: ".5rem",
        }}
      >
        {children}
      </Box>
    )
  }



  return (
    <>
      <CarrinhoPage
        handleBack={handleBack}
        handleNext={handleNext}
        steps={steps}
        activeStep={2}
        loadingButton={loadingConfirmePedido}
        disabledVoltarCompras={false}
        handleBackVoltarComprar={handlerComprarMais}
      >

        <List dense className={classes.List}>
          <Grid item className={classes.BlocoConferir}>
            {isLoading ? (
              <Skeleton height={50} width={200} animation="wave" />
            ) : (
              <>
                <Titulo sx={{ mb: 1 }}>Confira sua Solicitação</Titulo>
                <Titulo sx={{ fontSize: 16, mb: 3 }} color="text.secondary">
                  Chip Internacional
                </Titulo>
              </>
            )}

            {
              isLoading ? (
                <>
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton key={index + "ske"} height={50} width={"100%"} animation="wave" />
                  ))}
                </>

              ) :
                <>
                  <Divider />

                  <Titulo sx={{ marginTop: "1rem" }}>Dados Chip</Titulo>
                  <br />


                  {
                    loadingConfirmePedido ?
                      <>
                        {
                          Array.from({ length: 5 }, (_, index) => (
                            <Skeleton key={index + 'k'} animation="wave" height={50} />

                          ))
                        }

                      </>

                      :

                      dadosChip?.itens?.map((item, index) => (
                        <Fragment key={item?.id}>
                          <ContainerItem>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Pacotes de dados:
                            </Typography>

                            <Typography sx={{ fontWeight: "bold" }}>
                              {item.tamanho}
                            </Typography>
                          </ContainerItem>
                          <ContainerItem>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Pais:
                            </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {item.pais}
                            </Typography>
                          </ContainerItem>
                          <ContainerItem>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Dias Selecionados:
                            </Typography>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {item.dias}
                            </Typography>
                          </ContainerItem>
                        </Fragment>
                      ))}
                </>
            }

          </Grid>
        </List>
        <Divider />

        <br></br>

        {
          (isLoading || loadingConfirmePedido) ? (
            <Skeleton height={50} width={"100%"} animation="wave" />
          ) :
            <>
              {
                dadosChip &&
                dadosChip?.valorTotalMoedaNacional > 0 &&
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 0,
                    mb: 1,
                  }}
                >
                  <Titulo
                    sx={{ fontSize: 20, fontWeight: "bold" }}
                    label="Total"
                  ></Titulo>


                  <Titulo
                    sx={{
                      fontWeight: "bold",
                      textAlign: "end",
                      fontSize: 20,
                    }}
                    color="primary"
                    align="right"
                  >
                    R${" "}
                    {formatMoneyInStringForm(
                      dadosChip?.valorTotalMoedaNacional,
                      2
                    )}
                  </Titulo>
                </Box>}
            </>
        }

      </CarrinhoPage >
    </>
  );
}

import { useState, useEffect } from "react";
import { getDadoBancario } from "../../../api/Cadastro";
import Controle from "../ControleCamposMultiplos";

export default function DadoBancarioItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
}) {
  const [dadosForm, setDadosForm] = useState({});
  const [dadosDefault, setDadosDefault] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getDadoBancario(
      idCliente,
      idPerfilLimiteOperacional
    );

    setDadosDefault(() => ({ ...response[0] }))
    setDadosForm(() => ([...response]));
    setDada(() => ([...response]));
  };

  return (
    <>
      <Controle
        identificaItem="Dados Bancários"
        dadosForm={dadosForm}
        setDadosForm={setDadosForm}
        setData={setDada}
        defaultDadosForm={dadosDefault}
        title="Dado Bancário"
        setIdTipoEstadoCivil={null}
        tooltipAdd="Adicione um novo dado Bancário"
        tooltipDelete="Remove o dado bancário selecionada"
        initBuscaCampos={init}
      />
    </>
  );
}

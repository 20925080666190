import TiposPedido from '../../../util/typesTipoPedido';

export default function TituloPedidoConcluido({ pedido }) {


      const defineTitulo = () => {
            switch (pedido.tipoPedido.id) {
                  case TiposPedido.Recebimento:
                        return (
                              <>
                                    Estamos processando sua <br />
                                    ordem de pagamento no valor de:
                              </>
                        )
                  case TiposPedido.CompraEspecie:
                        return (
                              <>
                                    Estamos processando seu pedido <br />
                                    Valor final que irá receber é de:
                              </>
                        )


                  default:
                        return (
                              <>
                                    Estamos aguardando o <br />
                                    pagamento de:
                              </>
                        )
            }
      }

      return (defineTitulo());
}
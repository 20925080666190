import { AppRoutes } from "./Routes";
import { AuthProvider } from "./hooks/AuthContext";
import { QueryClientProvider, QueryClient } from "react-query";
import { Feedback } from "./components/Feedback";
import { Toaster } from "sonner";
import { useMediaQuery } from "@mui/material";





const client = new QueryClient();

function App() {
  const isTelaPequena = useMediaQuery("(max-width:728px)");

  return (
    <>
      <QueryClientProvider client={client}>
        <AuthProvider>
          <Feedback />
          <Toaster
            richColors
            position="top-center"
            visibleToasts={3}
            toastOptions={{
              style: {
                width: isTelaPequena ? "90%" : "125%",
              },
            }}
          />
          <AppRoutes />
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

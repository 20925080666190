import { useState, useEffect, Fragment } from "react";
import Skeleton from "@mui/material/Skeleton";
import AnexarComprovante from "../AnexarComprovante";
import { Grid, Typography, Box, Divider, Card } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useQuery } from "react-query";
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import useStyles from "./styles";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";
import CancelarPedidoCambio from "../CancelaPedidoCambio";
import FormasPagamentoSelecionado from "../PedidoConcluidoPagamentoEscolhido";
import TipoStatusPedido from "../../util/typesTipoStatusPedido";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Delivery from "../Delivery";
import TiposPedido from "../../util/typesTipoPedido";
import { formatMoneyInStringForm } from "../../util/helpers";
import DadosEntregaPedido from "../DadosEntregaPedido";
import TiposStatusPedido from "../../util/typesTipoStatusPedido";
import { useTheme } from "@mui/styles";
import Subtitulo from "../Textos/Subtitulo";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function DetalhePedidoChipInternacional({ dadosPedido, isLoading }) {
  const { user } = useAuth();
  const { showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyles();
  const [openModalPagamento, setOpenModalPagamento] = useState(false);
  const theme = useTheme();


  return (
    <>
      <Grid item xs={12}>
        <Grid>
          {isLoading &&
            [...Array(3)].map((_, index) => (
              <Skeleton width="auto" key={index} height={120} sx={{ mt: -4 }} />
            ))}
          {!isLoading && (
            <List sx={{ width: "100%" }}>
              <Box className={classes.Detalhes}>
                <Box className={classes.TitleBox}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                    }}
                  >
                    DETALHES DO PEDIDO
                    <span className={classes.Codigo}> #{dadosPedido?.id}</span>
                    <ListItem
                      sx={{ color: dadosPedido?.status?.id === TiposStatusPedido.Cancelado ? theme.palette.error.dark : theme.palette.primary.main }}
                      secondaryAction={
                        <ListItemText
                          primary={dadosPedido?.status?.descricao}

                        />
                      }
                      disablePadding
                    >
                      <ListItemText className={classes.Item}>
                        Status Pedido:
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <ListItemText
                          primary={format(
                            new Date(dadosPedido?.dataCadastro),
                            "dd/MM/yyyy"
                          )}
                        />
                      }
                      disablePadding
                    >
                      <ListItemText className={classes.Item}>
                        Data do pedido:
                      </ListItemText>
                    </ListItem>
                  </Typography>
                </Box>
                {dadosPedido?.status.id === 1 && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CancelarPedidoCambio
                      idPedido={dadosPedido?.id}
                      idCliente={user.id}
                    />
                  </Box>
                )}
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid sx={{ width: "100%" }} item lg={12}>
                    <Item>
                      <Box className={classes.ItensPedido}>
                        <Typography className={classes.Titulo}>
                          Chip Selecionado
                        </Typography>
                        <Divider sx={{ mt: 1 }} />
                        {dadosPedido?.chipInternacional?.length > 0 &&
                          dadosPedido?.chipInternacional?.map((item, index) => (
                            <Fragment key={item?.id + index}>
                              <List
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={`${item.nome}`}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    Chip Selecionado
                                  </ListItemText>
                                </ListItem>
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={`${item.tamanho}G`}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    Pacotes de dados:
                                  </ListItemText>
                                </ListItem>
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={`${item.dias}`}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    Dias disponíveis:
                                  </ListItemText>
                                </ListItem>
                              </List>
                            </Fragment>
                          ))}


                      </Box>
                    </Item>

                    {dadosPedido?.status &&
                      dadosPedido?.status.id ===
                      TipoStatusPedido.PagamentoIdentifica && (
                        <Item>
                          <List className={classes.formaPagamento}>
                            <Typography className={classes.Titulo}>
                              Ative seu chip

                            </Typography>
                            <Divider sx={{ mt: 1, mb: 3 }}></Divider>
                            <Card className={classes.cardContainer} >
                              <Box className={classes.qrCodeContainer}>
                                {!!dadosPedido?.chipInternacional[0]?.qrCodeBase64 ?
                                  <>
                                    <Subtitulo sx={{ color: "#060606" }}>
                                      Use o QR code para ativar o seu chip
                                    </Subtitulo>
                                    <br />
                                    <img
                                      className={classes.qrCode}
                                      alt="Pix"
                                      src={dadosPedido?.chipInternacional[0].qrCodeBase64}
                                      loading="lazy"
                                    />
                                    <br />
                                    <Subtitulo sx={{ color: "#060606", fontWeight: 'bold' }}>
                                      Obs.: Caso haja problemas com a ativação entrar em contato com:
                                      <Typography sx={{ fontWeight: 'bold', cursor: 'pointer !important' }}
                                        onClick={(e) => { window.location.href = 'mailto:contact@ezsimconnect.com'; }}>
                                        contact@ezsimconnect.com
                                      </Typography>

                                    </Subtitulo>


                                  </>

                                  :

                                  <Subtitulo sx={{ color: "#060606", mt: 1, mb: 1 }}>
                                    Estamos gerando o QR Code, por favor aguarde!
                                  </Subtitulo>
                                }

                              </Box>
                            </Card>
                          </List>
                        </Item>
                      )}

                    <Item>
                      <List className={classes.formaPagamento}>
                        <Typography className={classes.Titulo}>
                          Forma de pagamento
                        </Typography>
                        <Divider sx={{ mt: 1 }}></Divider>
                        <ListItem>
                          <ListItemText>
                            <FormasPagamentoSelecionado
                              pedido={dadosPedido}
                              isTodosDetalhes={false}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Item>
                  </Grid>
                  <Grid item lg={12} sx={{ width: "100%" }}>
                    <Item sx={{ paddingBottom: "50px", background: "#fcfcfc" }}>
                      <Box sx={{ pl: 1 }} className={classes.ItensPedido}>

                        {/* CASO CANCELADO */}
                        {dadosPedido?.status &&
                          dadosPedido?.status.id ===
                          TipoStatusPedido.Cancelado && (
                            <List
                              sx={{
                                width: "100%",
                                pb: 0,
                                pt: 1,
                              }}
                            >
                              <ListItem disablePadding>
                                <ListItemText>
                                  <Typography color="error">
                                    Motivo do cancelamento:
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemText>
                                  {dadosPedido?.motivoCancelamento}
                                </ListItemText>
                              </ListItem>
                            </List>
                          )}

                        <List>
                          <ListItem
                            style={{ borderTop: "1px solid #ccc" }}
                            secondaryAction={
                              <ListItemText
                                primary={
                                  <Typography
                                    color="black"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 20,
                                      mt: 4,
                                    }}
                                  >
                                    {"R$ " +
                                      formatMoneyInStringForm(dadosPedido?.valorTotal, 2)}
                                  </Typography>
                                }
                              />
                            }
                            disablePadding
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  color="black"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: 18,
                                    mt: 4,
                                  }}
                                >
                                  Valor Total:
                                </Typography>
                              }
                            ></ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    </Item>
                  </Grid>
                </Grid>

                {/* TODO: sumir com este botão caso o pedido já esteja cancelado, ainda nao foi retirado para poder arrumar layout */}
              </Box>
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
}

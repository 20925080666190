import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import { Feedback } from "../Feedback";
import { Grid } from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Tooltip from "@mui/material/Tooltip";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import SimCardIcon from '@mui/icons-material/SimCard';
import TypesTipoStatusCliente from "../../util/typesTipoStatusCliente";
import ErrorIcon from "@mui/icons-material/Error";
import { Alert, AlertTitle } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {
  useStyle as menuDashStyle,
  AppBar,
  Drawer,
  DrawerHeader,
} from "./styles";
import { LogoEmpresa } from "../";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import Copyright from "../Copyright";
import Collapse from "@mui/material/Collapse";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PaymentsIcon from "@mui/icons-material/Payments";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Hidden from "@mui/material/Hidden";
import WidgetsIcon from "@mui/icons-material/Widgets";
import PeopleIcon from "@mui/icons-material/People";
import UserClicle from "@mui/icons-material/AccountCircleOutlined";
import Lock from "@mui/icons-material/LockOutlined";
import Plus from "@mui/icons-material/ControlPointOutlined";
import Money from "@mui/icons-material/PaidOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Bank from "@mui/icons-material/AccountBalance";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useAuth } from "../../hooks/AuthContext";
import Notificacao from "../Notificacoes";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import theme from "../Paleta/theme";
import { getExistProximoNivelPerfisLimiteOperacional } from "../../api/LimiteOperacional";
import Cart from "../Cart";
import useAlert from "../../hooks/useAlert";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { GETALLVITRINESISHABILITADA } from "../../util/typesReactQuery";
import { useQuery } from "react-query";
import { getAllVitrinesIsHabilitada } from "../../api/Vitrine";
import LockIcon from "@mui/icons-material/Lock";
import TiposOperacao from "../../util/typesTipoOperacao";
import useOrdemPagamento from "../../hooks/useOrdemPagamento";
import useCliente from "../../hooks/useCliente";
import useConfigCarrinho from "../../hooks/useConfigCarrinho";
import usePedido from "../../hooks/usePedido";
import TiposStatusCliente from "../../util/typesTipoStatusCliente";
import useCheckPendencia from "../../hooks/useCheckPendencia";
import BadgeMenu from "../Badges/menu";

export const Paginas = {
  PaginaInicial: 0,
  MeusDados: 1,
  MeusPedidos: 2,
  AlterarSenha: 3,
  AumentoLimites: 4,
  Cripto: 5,
  Remessas: 6,
  Especie: 7,
  Cartao: 8,
  SeguroViagem: 9,
  Ouro: 10,
  ChipInternaciona: 11
};

export const SubMenus = {
  Envio: 1,
  Recebimento: 2,
  Beneficiario: 3,
  CompraMoeda: 4,
  VendaMoeda: 5,
  CompraCartao: 6,
  RecargaCartao: 7,
  VenderSaldoCartao: 8,
};

const menusMinhaConta = [
  {
    title: "Meus Dados",
    link: "/meusDados",
    icon: <UserClicle />,
    id: Paginas.MeusDados,
  },
  {
    title: "Meus Pedidos",
    link: "/MeusPedidos",
    icon: <FormatListNumberedIcon />,
    id: Paginas.MeusPedidos,
  },
  {
    title: "Alterar Senha",
    link: "/MinhaConta/alterarSenha",
    icon: <Lock />,
    id: Paginas.AlterarSenha,
  },
  {
    title: "Solicitar aumento de limites",
    link: "/ClienteLimiteOperacionalPlus",
    icon: <Plus />,
    id: Paginas.AumentoLimites,
  },
];

function stringAvatar(user) {
  let sigla = "",
    nome = "";

  if (!!user.nome) {
    nome = user.nome;

    if (nome[0] === " ") nome = nome.replace(" ", "");

    sigla =
      nome.split(" ").length > 1
        ? nome.split(" ")[0][0].toUpperCase()
        : nome.charAt(0).toUpperCase();
  } else {
    sigla = user.username[0].toUpperCase() + user.username[1].toUpperCase();
  }

  return {
    sx: {
      bgcolor: theme.palette.primary.main,
      width: "30px !important",
      height: "30px !important",
      fontSize: "15px !important",
    },
    children: sigla,
  };
}

function AvatarLight(user) {
  let sigla = "",
    nome = "";

  if (!!user.nome) {
    nome = user.nome;

    if (nome[0] === " ") nome = nome.replace(" ", "");

    sigla =
      nome.split(" ").length > 1
        ? nome.split(" ")[0][0] + nome.split(" ")[1][0]
        : nome.split(" ")[0][0];
  } else {
    sigla = user.username[0] + user.username[1];
  }

  return {
    sx: {
      bgcolor: theme.palette.primary.lighter,
      width: "35px !important",
      height: "35px !important",
      fontSize: "15px !important",
    },
    children: sigla,
  };
}

export default function MenuDashboard({
  children,
  pagina,
  subMenuSelecionado,
}) {
  const [open, setOpen] = useState(window.innerWidth <= 768 ? false : true);
  const theme = useTheme();
  const { user, signOut } = useAuth();
  const { hasPendencia, messageError: messageErrorPendencia } = useCheckPendencia();
  const {
    quantidadeOrdem,
    infoMessage: infoMessageOrdem,
    errosMessage: errorsMessageOrdem,
  } = useOrdemPagamento();
  const cssStyle = menuDashStyle();
  const [openSubmenus, setOpenSubmenus] = useState(null);
  const [menuDeseja, setMenuDeseja] = useState([]);
  const [showCopyright, setShowCopyright] = useState(true);
  const { isTokenAtivo, errorMessage } = useCliente();
  const { refetchPedido } = usePedido();
  const { errors: errorsConfiguraCarrinho, info: ConfiguraCarrinhoInfo } =
    useConfigCarrinho(refetchPedido);

  const navigation = useNavigate();

  const { showAlertInfo, showAlertError } = useAlert();

  useEffect(() => {
    if (!!infoMessageOrdem) showAlertInfo(infoMessageOrdem);

    if (!!errorsMessageOrdem) showAlertError(errorsMessageOrdem);

    if (!!ConfiguraCarrinhoInfo) showAlertInfo(ConfiguraCarrinhoInfo);

    if (!!errorsConfiguraCarrinho) showAlertError(errorsConfiguraCarrinho);

    if (!!messageErrorPendencia) showAlertError(messageErrorPendencia);
  }, [
    infoMessageOrdem,
    errorsMessageOrdem,
    errorsConfiguraCarrinho,
    ConfiguraCarrinhoInfo,
    messageErrorPendencia
  ]);

  useEffect(() => {
    if (errorMessage) showAlertError(errorMessage);

    if (isTokenAtivo === false) signOut();
  }, [isTokenAtivo, errorMessage]);

  const verificaVitrineAtivo = (vitrines, operacaoVeriricada) => {

    switch (operacaoVeriricada) {
      case TiposOperacao.EnvioDinheiro:
        return !vitrines.some((v) => v.textID === "REMESSA_EXTERIOR" && v.ativo) ?? true;

      case TiposOperacao.RecebimentoDinheiro:
        return !vitrines.some((v) => v.textID === "RECEBIMENTO_EXTERIOR" && v.ativo) ?? true;

      case TiposOperacao.turismoVendaEspecie:
        return !vitrines.some((v) => v.textID === "VITRINE_VENDA" && v.ativo) ?? true;

      case TiposOperacao.TurismoVendaCartao:
        return !vitrines.some((v) => v.textID === "VITRINE_VENDA" && v.ativo && v.possuiCartao === true) ?? true;

      case TiposOperacao.turismoCompraEspecie:
        return !vitrines.some((v) => v.textID === "VITRINE_COMPRA" && v.ativo) ?? true;

      case TiposOperacao.turismoCompraSaldoCartao:
        return !vitrines.some((v) => v.textID === "VITRINE_COMPRA" && v.ativo && v.possuiCartao === true) ?? true;

      case TiposOperacao.TurismoVendaOuro:
        return !vitrines.some((v) => v.textID === "VITRINE_VENDA_OURO" && v.ativo) ?? true;

      case TiposOperacao.SeguroViagem:
        return !vitrines.some((v) => v.textID === "SEGURO_VIAGEM" && v.ativo) ?? true;

      case TiposOperacao.ChipInternacional:
        return !vitrines.some((v) => v.textID === "CHIP_INTERNACIONAL" && v.ativo) ?? true;

      default:
        return true;

    }


  };

  const configuraMenus = (vitrines) => {
    let menuConfigurado = [];
    let subMenuConfigurado = [];
    let menu = {};
    let disabled = false;

    menu = {
      id: Paginas.Remessas,
      title: "Remessas internacionais",
      link: "",
      icon: <CompareArrowsIcon />,
      pagina: Paginas.Remessas,
      submenus: [],
    };

    subMenuConfigurado.push({
      id: SubMenus.Envio,
      title: "Enviar Para o Exterior",
      icon: <DoubleArrowIcon />,
      disabled: verificaVitrineAtivo(vitrines, TiposOperacao.EnvioDinheiro),
      link: "/carrinho/transferencia-internacional/envio/vitrine",
      pagina: Paginas.Remessas,
    });

    subMenuConfigurado.push({
      id: SubMenus.Recebimento,
      title: "Receber do Exterior",
      disabled: verificaVitrineAtivo(
        vitrines,
        TiposOperacao.RecebimentoDinheiro
      ),
      icon: <DoubleArrowIcon style={{ transform: "rotate(180deg)" }} />,
      link: "/carrinho/transferencia-internacional/recebimento/vitrine",
    });

    if (subMenuConfigurado.some((s) => s.disabled === false)) {
      disabled = false;
    } else {
      disabled = true;
    }

    subMenuConfigurado.push({
      id: SubMenus.Beneficiario,
      title: "Beneficiários",
      disabled: disabled,
      icon: <PeopleIcon />,
      link: "/Recebedor",
    });

    menu.submenus = [...subMenuConfigurado];

    menu.disabled = menu.submenus.every((s) => s.disabled === true);

    menuConfigurado.push(menu);

    subMenuConfigurado = [];
    menu = {};

    menu = {
      id: Paginas.Especie,
      title: "Moedas em espécie",
      link: "",
      icon: <Money />,
      pagina: Paginas.Especie,
      submenus: [],
    };

    subMenuConfigurado.push({
      id: SubMenus.CompraMoeda,
      title: "Comprar Moeda",
      disabled: verificaVitrineAtivo(
        vitrines,
        TiposOperacao.turismoVendaEspecie
      ),
      icon: <AddShoppingCartIcon />,
      link: "/carrinho/turismo-compra/especie/vitrine",
      pagina: Paginas.Especie,
    });

    subMenuConfigurado.push({
      id: SubMenus.VendaMoeda,
      title: "Vender Moeda",
      disabled: verificaVitrineAtivo(
        vitrines,
        TiposOperacao.turismoCompraEspecie
      ),
      icon: <PaymentsIcon style={{ transform: "rotate(180deg)" }} />,
      link: "/carrinho/turismo-venda/especie/vitrine",
      pagina: Paginas.Especie,
    });

    menu.submenus = [...subMenuConfigurado];

    menu.disabled = menu.submenus.every((s) => s.disabled === true);

    menuConfigurado.push(menu);

    subMenuConfigurado = [];
    menu = {};

    menu = {
      id: Paginas.Cartao,
      title: "Cartão Pré Pago Internacional",
      link: "",
      icon: <CreditCardIcon />,
      submenus: [],
    };

    subMenuConfigurado.push({
      id: SubMenus.CompraCartao,
      title: "Comprar Cartão",
      disabled: verificaVitrineAtivo(
        vitrines,
        TiposOperacao.TurismoVendaCartao
      ),
      icon: <AddShoppingCartIcon />,
      link: "/carrinho/turismo-compra/cartao/vitrine",
      pagina: Paginas.Cartao,
    });

    subMenuConfigurado.push({
      id: SubMenus.RecargaCartao,
      title: "Recarregar Cartão",
      disabled: verificaVitrineAtivo(
        vitrines,
        TiposOperacao.TurismoVendaCartao
      ),
      icon: <AddShoppingCartIcon />,
      link: "/carrinho/turismo-compra/cartao-recarga/vitrine",
      pagina: Paginas.Cartao,
    });

    subMenuConfigurado.push({
      id: SubMenus.VenderSaldoCartao,
      title: "Vender Saldo",
      disabled: verificaVitrineAtivo(
        vitrines,
        TiposOperacao.turismoCompraSaldoCartao
      ),
      icon: <PaymentsIcon style={{ transform: "rotate(180deg)" }} />,
      link: "/carrinho/turismo-venda/cartao-saldo/vitrine",
      pagina: Paginas.Cartao,
    });

    menu.submenus = [...subMenuConfigurado];

    menu.disabled = menu.submenus.every((s) => s.disabled === true);

    menuConfigurado.push(menu);

    subMenuConfigurado = [];
    menu = {};

    menu = {
      id: Paginas.Ouro,
      title: "Comprar ouro",
      link: "/carrinho/Compra-ouro/vitrine",
      icon: <Bank />,
      disabled: verificaVitrineAtivo(vitrines, TiposOperacao.TurismoVendaOuro),
      submenus: [],
    };

    menuConfigurado.push(menu);

    menu = {
      id: Paginas.SeguroViagem,
      title: "Seguro Viagem",
      link: "/SeguroViagem",
      icon: <AirplanemodeActiveIcon />,
      // disabled: true,
      disabled: verificaVitrineAtivo(vitrines, TiposOperacao.SeguroViagem),
      pagina: Paginas.SeguroViagem,
      submenus: [],
    };

    menuConfigurado.push(menu);

    menu = {
      id: Paginas.ChipInternaciona,
      title: "Chip Internacional",
      link: "/ChipInternacional",
      icon: <SimCardIcon />,
      // disabled: true,
      disabled: verificaVitrineAtivo(vitrines, TiposOperacao.ChipInternacional),
      pagina: Paginas.SeguroViagem,
      submenus: [],
    };

    menuConfigurado.push(menu);

    setMenuDeseja([...menuConfigurado]);
  };

  useQuery([GETALLVITRINESISHABILITADA], () => getAllVitrinesIsHabilitada(), {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhuma vitrine - R8MRE`
        );
        return;
      }
      if (data.errorMessage) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RXKCB`
        );
        return;
      }

      configuraMenus(data.data);
    },
    onError: (error) => {
      showAlertError(
        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RTEBE`
      );
    },
    refetchOnWindowFocus: true,
  });

  const handleClick = (idMenu) => (event) => {
    if (openSubmenus) {
      setOpenSubmenus(null);
      setShowCopyright(true); // Atualiza o estado quando o submenu for fechado
    } else {
      setOpenSubmenus(idMenu);
      setShowCopyright(false); // Atualiza o estado quando o submenu for aberto
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = menuDashStyle();

  const [menusMinhaContaState, setMenusMinhaContaState] = useState(null);

  const init = async (idCliente) => {
    const response = await getExistProximoNivelPerfisLimiteOperacional(
      idCliente
    );

    const newSubMenu = response
      ? menusMinhaConta
      : menusMinhaConta.filter((f) => f.pagina !== Paginas.AumentoLimites);

    setMenusMinhaContaState(newSubMenu);
  };

  useEffect(() => {
    init(user.id);
  }, [user]);

  const userMenuOpen = Boolean(anchorEl);

  const handlerUseMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlerResizeScreen = () => {
    if (window.innerWidth < 720) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handlerResizeScreen);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getNome = () => {
    let nome = "";

    if (!!user.nome) {
      nome = user.nome;

      if (nome[0] === " ") nome = nome.replace(" ", "");

      let nomeDividido = nome.split(" ");

      return nomeDividido[0];
    }

    if (!!user.username) return user.username;

    if (!!user.emailPrincipal) return user.emailPrincipal;
  };

  const handlerChangeUrl = (url, disabled) => (event) => {
    event.preventDefault();
    if (disabled) {
      showAlertInfo(
        "No momento ainda não possuimos essa opção, por gentileza entrar em contato para mais detalhes."
      );
      return;
    }

    navigation(url);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar position="fixed" open={open}>
          {user &&
            (user.idTipoStatusCliente === 1 || user.idTipoStatusCliente === 5) ? (
            <Box
              className="w-full"
              sx={{ backgroundColor: "rgb(253, 237, 237)", padding: "10px" }}
            >
              {user.idTipoStatusCliente ===
                TiposStatusCliente.AguardandoAprovacao ? (
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    textAlign: "center",
                    color: "rgb(95, 33, 32)",
                  }}
                >
                  <ErrorIcon
                    style={{ fontSize: 14, color: "rgb(95, 33, 32)", marginRight: '20px' }}
                  />
                  {` `}Seu cadastro está sendo validado, por favor, tente novamente
                  mais tarde.
                </Typography>
              ) : user.idTipoStatusCliente === TiposStatusCliente.Bloqueado ? (
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    textAlign: "center",
                    color: "rgb(95, 33, 32)",
                    marginLeft: '30px'
                  }}
                >
                  Seu acesso está bloqueado, por favor, entre em contato conosco
                  para reativação.
                </Typography>
              ) : (
                ""
              )}
            </Box>
          ) : null}
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box className={classes.DrawerHeader}>
              <Hidden smDown>
                <Typography sx={{ fontSize: "16px !important" }}>
                  Bem vindo,
                  <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                    {getNome()}
                  </span>
                </Typography>
              </Hidden>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  onClick={() => {
                    navigation("/MinhaConta/contato");
                  }}
                  title="Ajuda"
                >
                  <IconButton>
                    <HelpIcon sx={{ color: "#777" }} />
                  </IconButton>
                </Tooltip>
                <Cart />

                <Notificacao />

                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={userMenuOpen ? "long-menu" : undefined}
                  aria-expanded={userMenuOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handlerUseMenuOpen}
                >
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center" }}
                    spacing={0}
                  >
                    <Avatar {...stringAvatar(user)} />
                    <KeyboardArrowDownIcon />
                  </Stack>
                </IconButton>

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={userMenuOpen}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  <Hidden smUp>
                    <Feedback />
                  </Hidden>

                  <MenuItem
                    onClick={() => {
                      navigation("/MinhaConta/contato");
                    }}
                  >
                    Ajuda
                  </MenuItem>

                  <Divider sx={{ mt: 1, color: "#ddd" }} />
                  <MenuItem sx={{ color: "red" }} onClick={signOut}>
                    Sair
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            style={{
              justifyContent: "space-between",

              background: "#f6f6f6 !important",
            }}
          >
            <LogoEmpresa isTopDashboard={true} />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Hidden smUp>
            <Typography
              sx={{
                fontSize: "13px !important",
                ml: 2,
                mb: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              {user.nome !== null && (
                <>
                  <Stack
                    direction="row"
                    sx={{ alignItems: "center" }}
                    spacing={0}
                  >
                    <Avatar {...AvatarLight(user)} />
                  </Stack>
                  <span style={{ fontWeight: "bold", marginLeft: 5 }}>
                    {user.nome}
                  </span>
                </>
              )}
            </Typography>
          </Hidden>

          <List>
            <ListItem
              component={Link}
              to="/minhaConta"
              disablePadding
              className={
                Number(pagina) === Paginas.PaginaInicial
                  ? `${cssStyle.textItemList} ${cssStyle.check}`
                  : `${cssStyle.textItemList}`
              }
              sx={{
                display: "block",
                marginBottom: "0.2rem",
                textDecoration: "none !important",
              }}
            >
              <ListItemButton
                onClick={handleClick}
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <WidgetsIcon />
                </ListItemIcon>

                <ListItemText
                  primary="Página Inicial"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: theme.palette.text.primary,
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ color: "#ddd" }} />

            {menusMinhaContaState &&
              menusMinhaContaState.map((menu, index) => (
                <>
                  <ListItem
                    key={index}
                    component={Link}
                    to={menu.link}
                    className={
                      menu.id === pagina
                        ? `${cssStyle.textItemList} ${cssStyle.check}`
                        : `${cssStyle.textItemList}`
                    }
                    disablePadding
                    sx={{ display: "block", textDecoration: "none !important" }}
                  >
                    <ListItemButton
                      key={index}
                      sx={{
                        minHeight: 40,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      {menu.id === Paginas.MeusDados && hasPendencia ?
                        <>
                          <BadgeMenu>
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {menu.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={menu.title}
                              sx={{
                                opacity: open ? 1 : 0,
                                color: theme.palette.text.primary,
                              }}
                            />
                          </BadgeMenu>
                        </>
                        :
                        <>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {menu.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={menu.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              color: theme.palette.text.primary,
                            }}
                          />
                        </>
                      }
                    </ListItemButton>
                  </ListItem>
                </>
              ))}
          </List>
          <Divider sx={{ color: "#ddd" }} />
          <List>
            {menuDeseja?.length > 0 &&
              menuDeseja?.map((menu, index, submenus) => (
                <Fragment key={index}>
                  <ListItem

                    onClick={
                      !!menu.link
                        ? handlerChangeUrl(menu.link, menu.disabled)
                        : null
                    }
                    className={
                      menu.disabled
                        ? classes.subMenuDisabled
                        : menu.id === pagina
                          ? `${cssStyle.textItemList} ${cssStyle.check}`
                          : `${cssStyle.textItemList}`
                    }
                    disablePadding
                    sx={{ display: "block", textDecoration: "none !important" }}
                  >
                    <ListItemButton
                      key={index}
                      onClick={
                        menu?.disabled
                          ? (e) =>
                            showAlertInfo(
                              "No momento ainda não possuimos essa opção, por gentileza entrar em contato para mais detalhes."
                            )
                          : handleClick(menu.id)
                      }
                      id={menu.id}
                      sx={{
                        minHeight: 40,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >


                      {menu.id === Paginas.Remessas && !!quantidadeOrdem ? (
                        <BadgeMenu
                          valor={quantidadeOrdem}
                          color="primary"
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {menu.disabled ? <LockIcon /> : menu.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={menu.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              color: theme.palette.text.primary,
                            }}
                          />
                        </BadgeMenu>
                      ) : (
                        <>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {menu.disabled ? <LockIcon /> : menu.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={menu.title}
                            sx={{
                              opacity: open ? 1 : 0,
                              color: theme.palette.text.primary,
                            }}
                          />
                        </>
                      )}
                    </ListItemButton>
                    <Collapse
                      in={openSubmenus === menu.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {menu?.submenus?.length > 0 &&
                          menu?.submenus?.map((submenu, i) => (
                            <>
                              <ListItemButton
                                key={i}
                                onClick={handlerChangeUrl(
                                  submenu.link,
                                  submenu.disabled
                                )}
                                sx={{ pl: 4 }}
                                className={
                                  submenu.disabled
                                    ? classes.subMenuDisabled
                                    : subMenuSelecionado === submenu.id
                                      ? classes.subMenuCheck
                                      : classes.subMenu
                                }
                              >
                                {submenu.id === SubMenus.Recebimento &&
                                  !!quantidadeOrdem ? (
                                  <BadgeMenu
                                    valor={quantidadeOrdem}
                                    color="primary"
                                  >
                                    <ListItemIcon style={{ opacity: 0.7 }}>
                                      {submenu.disabled ? (
                                        <LockIcon />
                                      ) : (
                                        submenu.icon
                                      )}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={submenu.title}
                                      style={{ color: "#777" }}
                                    />
                                  </BadgeMenu>
                                ) : (
                                  <>
                                    <ListItemIcon style={{ opacity: 0.7 }}>
                                      {submenu.disabled ? (
                                        <LockIcon />
                                      ) : (
                                        submenu.icon
                                      )}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={submenu.title}
                                      style={{ color: "#777" }}
                                    />
                                  </>
                                )}
                              </ListItemButton>
                            </>
                          ))}
                      </List>
                    </Collapse>
                  </ListItem>
                </Fragment>
              ))}

            <Link
              style={{ textDecoration: "none !important" }}
              to="/minhaConta/ConvidarAmigos"
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ShareOutlinedIcon />
                </ListItemIcon>

                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: 12 }}>
                      Convidar amigos
                    </Typography>
                  }
                  sx={{
                    fontSize: 12,
                    opacity: open ? 1 : 0,
                    color: theme.palette.text.primary,
                  }}
                />
              </ListItemButton>
            </Link>

            {open && (
              <ListItem
                className={classes.ListItem}
                sx={{ position: "fixed", bottom: 0, maxWidth: "20%" }}
              >
                {showCopyright && <Copyright />}
              </ListItem>
            )}
          </List>
        </Drawer>
        <Grid container>
          <Box
            style={{ marginTop: "4em" }}
            component="main"
            sx={{ flexGrow: 1, p: 0 }}
          >
            {children}
          </Box>
        </Grid>
      </Box>
    </>
  );
}

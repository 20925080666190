import { useState, useEffect } from "react";
import { getSocio } from "../../../api/Cadastro";
import Controle from "../ControleCamposMultiplos";
import { Skeleton } from "@mui/material";

export default function SocioItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const [dadosForm, setDadosForm] = useState(null);
  const [defaultDadosForm, setDefaultDadosForm] = useState({});
  const [idTipoEstadoCivil, setIdTipoEstadoCivil] = useState(1);


  useEffect(() => {
    init(idTipoEstadoCivil);

  }, []);

  const init = async (idTipoEstadoCivil, array, index) => {
    const response = await getSocio(
      idCliente,
      idTipoEstadoCivil,
      idPerfilLimiteOperacional
    );


    if (array?.length > 0) {
      array[index] = response[index] ?? response[0];

      setDadosForm(() => ([...array]));
      setDada(() => ([...array]));
    } else {
      setDefaultDadosForm(() => ({ ...response[0] }));
      setDadosForm(() => ([...response]));
      setDada(() => response);
    }


  };

  return (
    <>
      {
        isLoading ?
          <>
            {Array.from({ length: 2 }, () => (
              <Skeleton
                animation="wave"
                height={80} />
            ))}
          </>
          :
          <>

            <Controle
              identificaItem='Sócio'
              dadosForm={dadosForm}
              setDadosForm={setDadosForm}
              setData={setDada}
              defaultDadosForm={defaultDadosForm}
              title="Sócio"
              tooltipAdd="Adicione um sócio"
              tooltipDelete="Remove o sócio selecionado"
              initBuscaCampos={init}
              idTipoEstadoCivil={idTipoEstadoCivil}
              setIdTipoEstadoCivil={setIdTipoEstadoCivil}
            />
          </>
      }



    </>
  );
}

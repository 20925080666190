import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  emfaseText: {
    "& span": {
      color: theme.palette.primary.main,
      fontSize: "1.5rem !important",
      fontWeight: "600 !important",
    },
  },

  fontBold: {
    fontWeight: 900,
  },

  cardContainer: {
    borderWidth: "1px !important",
    width: '32rem !important',
    margin: 'auto',
    boxShadow: '0px 0px 4.5px 1px rgba(0,0,0,.3)',
  },

  qrCodeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    background: `#E1E1E1`,
    borderRadius: 'inherit',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },

  qrCode: {
    background: "#fff",
    height: theme.spacing(20),
  },

  Detalhes: {

    borderRadius: 4,
    minHeight: "60vh",
  },
  Codigo: {
    color: theme.palette.primary.main,
  },
  Card: {
    display: "flex",
    boxShadow: "none !important",
    width: "100%",
    height: "6em",
    borderRadius: "12px",
    background: "#fff !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      boxShadow: "none !important",
      width: "100%",
      height: "3em",

      borderRadius: "12px",
      background: "#fff !important",
    },
  },
  formaPagamento: {

  },
  ItensPedido: {
    borderRadius: 5,
  },
  TitleBox: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
    }
  },
  Titulo: {
    fontSize: "14px !important",
    fontWeight: 'bold !important',
    color: "black",
    alignItems: "center",
    display: "flex",
    textAlign: "left",
    paddingTop: 5,
  },
  Item: {
    color: "black",
  },
}));

export default useStyle;

import { useState, Fragment, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { Grid, Typography, Button, useTheme, Alert } from "@mui/material";
import Titulo from "../Textos/Titulo";
import Subtitulo from "../Textos/Subtitulo";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CampoTexto, CartaoViagemVisual } from "../";
import useStyles from "./styles";
import {
  addNumeroCartaoRecarga,
  getParametrosCartao,
} from "../../api/Carrinho/Turismo";
import { useMutation } from "react-query";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import TiposItemCarrinho from "../../util/typesTipoItemCarrinho";
import { formatMoneyInString } from "../../util/helpers";
export default function Cartao({
  isRecarga,
  isCompraSaldo,
  onCartaoAdicionalChange,
  isAdicional,
  setLoading,
  loading,
  item,
  praca,
  refetchItens,
  setDadosCartao,
  dadosCartao,
}) {
  const classes = useStyles();
  const [parametrosCartao, setParametrosCartao] = useState({});
  const [numeroCartao, setNumeroCartao] = useState("");
  // const [isRecarga, setIsRecarga] = useState(padraoIsRecarga);
  const [possuiAdicional, setPossuiAdicional] = useState(isAdicional);
  const [numeroIsValido, setNumeroIsValido] = useState(false);
  const [mensagemValidacao, setMensagemValidacao] = useState("");
  // const [loading, setLoading] = useState(0);
  const { showAlertError, showAlertInfo } = useAlert();
  const { user } = useAuth();
  const theme = useTheme();
  const [isCartaoValidado, setCartaoValidado] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const paramsCartao = await getParametrosCartao();
    setParametrosCartao(paramsCartao);

    if (isRecarga || isCompraSaldo)
      setDadosCartao({
        ...dadosCartao,
        maximoDigitos: paramsCartao.data.maximoDigitos,
        minimoDigitos: paramsCartao.data.minimoDigitos,
      });
    setNumeroCartao(item?.numeroCartao ?? "");
  };

  const validaCartao = () => {
    let isValido = true;
    if (!isRecarga || !isCompraSaldo) return;

    if (numeroCartao.length === 0)
      showAlertError("É necessário informar a numeração do cartão");

    if (numeroCartao.length > parametrosCartao?.data?.maximoDigitos) {
      isValido = false;
      showAlertError(
        `A numeração do cartão informada ultrapassa o máximo de dígitos exigido. O máximo exigido é de ${parametrosCartao?.data?.maximoDigitos} dígitos`
      );
    } else if (numeroCartao.length < parametrosCartao?.data?.minimoDigitos) {
      isValido = false;

      showAlertError(
        `A numeração do cartão informada é menor que o mínimo de dígitos exigido. O mínimo exigido é de ${parametrosCartao?.data?.minimoDigitos} dígitos`
      );
    } else {
      isValido = true;
    }

    setCartaoValidado(isValido);
    setNumeroIsValido(isValido);
  };

  const mutation = useMutation(addNumeroCartaoRecarga, {
    onSuccess: (data) => {
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - R57FD");

      refetchItens();

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    },
    onError: async (err) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R8A7E`
      );
    },
  });

  const saveNumberCartao = (number) => {
    validaCartao();

    setLoading(true);

    mutation.mutate({
      idCliente: user.id,
      idHotsite: user.idHotsite ?? 0,
      idPraca: praca.id,
      idTipoPedido: item.idTipoOperacaoCarrinho,
      idMoeda: item.moeda.id,
      numeroCartao: number,
    });
  };

  const handleChangeNumero = (e) => {
    setCartaoValidado(false);
    setNumeroCartao(e.target.value);
    setDadosCartao({ ...dadosCartao, codigo: e.target.value });

    e.preventDefault();
  };

  const handleChangeAdicional = (event) => {
    setPossuiAdicional(event.target.checked);
    onCartaoAdicionalChange(item, event.target.checked);
  };

  const handleOnBlurNumeroCartao = (e) => {
    setNumeroCartao(e.target.value);
    saveNumberCartao(e.target.value);
    e.preventDefault();
    validaCartao();
  };

  return (
    <>

      <Grid className={classes.blocoPedidos} item xs={12}>
        <Grid className={classes.PageInicial}>
          {loading ? (
            <Skeleton height={70} width={200} animation="wave" />
          ) : (
            <Titulo sx={{ fontSize: 18, mt: 2, mb: 1 }}>
              Informações do cartão
            </Titulo>
          )}

          {(isRecarga ||
            item?.idTipoItemCarrinho === TiposItemCarrinho.CompraSaldoCartao) &&
            (loading ? (
              <>
                <Skeleton
                  height={300}
                  width={"100%"}
                  sx={{ mt: "-4rem", mb: "-3rem" }}
                  animation="wave"
                />
                <Skeleton
                  height={50}
                  width={"100%"}
                  sx={{ mb: "0rem" }}
                  animation="wave"
                />
              </>
            ) : (
              <>
                <Grid container>
                  <CartaoViagemVisual
                    numero={numeroCartao}
                    quantidadeCaracteres={parametrosCartao?.data?.maximoDigitos}
                  />

                  <CampoTexto
                    handlerChange={handleChangeNumero}
                    name={numeroCartao}
                    fullWidth={true}
                    maximoDigitos={parametrosCartao?.data?.maximoDigitos}
                    onBlur={handleOnBlurNumeroCartao}
                    size="small"
                    value={numeroCartao}
                    isError={numeroIsValido || numeroCartao === ""}
                    label={
                      "Número com até " +
                      parametrosCartao?.data?.maximoDigitos +
                      " dígitos no verso do cartão"
                    }
                    enable={true}
                  />

                  {isCartaoValidado && (
                    <Alert sx={{ width: "100%" }}>
                      Os digitos do seu cartão foram registrados
                    </Alert>
                  )}
                </Grid>
              </>
            ))}

          {loading ? (
            <Skeleton
              height={50}
              width={150}
              sx={{ mb: "0rem" }}
              animation="wave"
            />
          ) : (
            <>
              {item?.idTipoItemCarrinho == TiposItemCarrinho.VendaCartao && (
                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Subtitulo>Valor do novo cartão: </Subtitulo>
                  <Subtitulo sx={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    R$ {parametrosCartao?.data?.valorNovo || 0.0}
                  </Subtitulo>
                </Grid>
              )}
            </>
          )}

          {!isRecarga &&
            item?.idTipoItemCarrinho == TiposItemCarrinho.VendaCartao &&
            (loading ? (
              <Skeleton
                height={50}
                width={"100%"}
                sx={{ mb: "0rem" }}
                animation="wave"
              />
            ) : (
              <FormControlLabel
                sx={{ mb: 1 }}
                control={
                  <Checkbox
                    checked={possuiAdicional}
                    onChange={handleChangeAdicional}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Solicitar cartão adicional (Para seu companheiro(a) de viagem)"
              />
            ))}

          {possuiAdicional && (
            <>
              {item?.idTipoItemCarrinho == TiposItemCarrinho.VendaCartao && (
                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Subtitulo>Valor cartão adicional: </Subtitulo>
                  <Subtitulo sx={{ fontWeight: "bold", fontSize: 16 }}>
                    {" "}
                    R${parametrosCartao?.data?.valorAdicional || 0.0}
                  </Subtitulo>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import { useEffect, useState } from "react";
import MenuDashboard from "../../../components/MenuDashboard";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { ptBR } from "date-fns/locale";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { getNotificacaoCliente } from "../../../api/Notificacao";
import { formatDistance, formatDistanceToNow } from "date-fns";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../../hooks/AuthContext";
import useStyles from "./styles";
import { Box, Button, Pagination, Popover, Skeleton } from "@mui/material";
import { ButtonAcaoGrande, Subtitulo } from "../../../components";
import useAlert from "../../../hooks/useAlert";
import { useQuery } from "react-query";
import useNotificationNaoLida from "../../../hooks/useNotifications/useNotificationsNaoLida";
import useNotificationLidas from "../../../hooks/useNotifications/useNotificationsLidas";

export function Notificacoes() {
  const { showAlertError } = useAlert();
  const classes = useStyles();
  const {
    refetchNotificacoesNaoLidas,
    notificacoesNaoLidas,
    setMarcarComoLida,
    handlerNextPageNaoLidas,
    hasNextPageNaoLidas,
    isLoadingNotNaoLida,
    errorNotNaoLida,
    setTodasComoLida,
  } = useNotificationNaoLida();

  const {
    notificacoesLida,
    refetchNotificacoesLidas,
    handlerNextPageLidas,
    hasNextPageLidas,
    isLoadingNotLida,
    errorNotLida,
  } = useNotificationLidas();

  const [isLoading, setIsLoading] = useState(false);
  const [notificacoes, setNotificacoes] = useState(null);
  const [isLido, setIsLido] = useState(false);

  const configuraNotificacao = (lido) => {
    if (lido) {
      setNotificacoes(notificacoesLida);
      return;
    }

    if (!lido) {
      setNotificacoes(notificacoesNaoLidas);
      return;
    }
  };

  useEffect(() => {
    refetchNotificacoesNaoLidas();
  }, []);

  useEffect(() => {
    if (errorNotLida) {
      showAlertError(`Ops, algo deu errado :  ${errorNotLida}`);
      return;
    }

    if (errorNotNaoLida) {
      showAlertError(`Ops, algo deu errado :  ${errorNotNaoLida}`);
      return;
    }
  }, [errorNotNaoLida, errorNotLida]);

  useEffect(() => {
    // if (!!notificacoesNaoLidas?.length) {
    configuraNotificacao(false);
    // }
  }, [notificacoesNaoLidas]);

  useEffect(() => {
    // if (!!notificacoesLida?.length) {
    configuraNotificacao(true);
    // }
  }, [notificacoesLida]);

  const handlerChangeLido = (lido) => (event) => {
    setIsLido(lido);
  };

  useEffect(() => {
    if (isLido) {
      refetchNotificacoesLidas();
      configuraNotificacao(isLido);
      return;
    }

    refetchNotificacoesNaoLidas();
    configuraNotificacao(isLido);
  }, [isLido]);

  const handlerCarregarMais = () => {
    setIsLoading(true);
    if (isLido) {
      handlerNextPageLidas();
      refetchNotificacoesLidas();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return;
    }

    handlerNextPageNaoLidas();
    refetchNotificacoesNaoLidas();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>

      <MenuDashboard pagina>
        {/* <Skeleton width="auto" height={50} animation="wave" /> */}
        <Grid
          className={classes.Container}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12}>
            <Box sx={{ display: "flex", p: 2 }}>
              <Button
                sx={{ fontSize: 14 }}
                variant="link"
                className={
                  isLido
                    ? [classes.optionsLido]
                    : [classes.check, classes.optionsLido]
                }
                onClick={handlerChangeLido(false)}
              >
                {isLido ? "Não lidas" : " ✔ Não lidas"}
              </Button>

              <Button
                sx={{ fontSize: 14 }}
                variant="link"
                className={
                  isLido
                    ? [classes.check, classes.optionsLido]
                    : [classes.optionsLido]
                }
                onClick={handlerChangeLido(true)}
              >
                {isLido ? " ✔ Lidas" : "Lidas"}
              </Button>
            </Box>
            <Divider sx={{ ml: "-2rem" }} />
          </Grid>

          <Grid item className={classes.Grid} xs={10} md={10}>
            {isLoadingNotLida || isLoadingNotNaoLida ? (
              new Array(5)
                .fill(1)
                .map((i, index) => (
                  <Skeleton
                    key={index}
                    width="auto"
                    height={50}
                    animation="wave"
                  />
                ))
            ) : (
              <>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={"1rem"} sx={{ mb: 2, ml: 2 }}>
                    Contém {notificacoes && notificacoes?.length} ou mais
                    notificações {isLido ? "lidas" : "não lidas"}
                  </Typography>

                  {!isLido && notificacoes && notificacoes.length > 0 && (
                    <Button
                      size="small"
                      onClick={setTodasComoLida}
                      variant="contained"
                      sx={{
                        mt: 2,
                        mb: 4,
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      Marcar todas como lida
                    </Button>
                  )}
                </Grid>

                {!!notificacoes && notificacoes.length > 0 ? (
                  notificacoes.map((notificacao, index) => (
                    <List sx={{ width: "100%" }} key={notificacao?.id}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar>
                            {" "}
                            <CircleNotificationsIcon />{" "}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={notificacao.titulo}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {notificacao.texto}
                              </Typography>

                              <Typography
                                component="span"
                                variant="body2"
                                color="primary"
                              >
                                <br></br>
                               
                                {formatDistanceToNow(
                                  new Date(notificacao.dataCadastro),
                                  {
                                    locale: ptBR,
                                    addSuffix: true,
                                  }
                                )}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>
                  ))
                ) : (
                  <>
                    <List
                      sx={{ width: "100%", backgroundColor: "transparent" }}
                    >
                      <ListItem alignItems="flex-start">
                        <Subtitulo>Não consta nenhuma notificação</Subtitulo>
                      </ListItem>
                    </List>
                  </>
                )}
              </>
            )}

            {(hasNextPageLidas || hasNextPageNaoLidas) && (
              <>
                <Box>
                  {isLoadingNotLida || isLoadingNotNaoLida || isLoading ? (
                    <>
                      <Skeleton width="auto" height={50} animation="wave" />
                    </>
                  ) : (
                    <>
                      <Grid sx={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          size="small"
                          onClick={handlerCarregarMais}
                          variant="contained"
                          sx={{
                            mt: 2,
                            mb: 4,
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          Carregar mais +
                        </Button>
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </MenuDashboard>
    </>
  );
}
